import React from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import './index.css';

import { AppContainer } from "react-hot-loader";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Data from "./views/Data";
import RealTime from "./views/RealTime";
// import Income from "./views/Income";
import Product from "./views/Product";
// import User from "./views/User";
import SettingModal from './views/component/SettingModal'
// import BookAssistant from "./views/BookAssistant";
// import CommunityData from "./views/CommunityData";
import newHome from './views/newHome'
import XinGaoKao from './views/XinGaoKao';
import LibraryData from './views/LibraryData';
import LibraryService from './views/LibraryService';
import BigClassroom from './views/BigClassroom';

import './assets/sass/component.scss';
import * as serviceWorker from './serviceWorker';

function render() {
  ReactDOM.render(
    <AppContainer>
      <Router>
        <React.Fragment>

          <Route exact path="/" component={newHome} />
          {/* 新大屏首页 */}
          <Route exact path="/newHome" component={newHome} />

          {/*实时行为动态*/}
          <Route path="/realtime" component={RealTime} />
          {/*收益总览*/}
          <Route path="/data" component={Data} />
          {/*产品体验*/}
          <Route path="/product" component={Product} />
          {/* 用户 */}
          {/* <Route path="/user" component={User} /> */}
          {/*小程序总览*/}
          {/* <Route path="/community" component={CommunityData} /> */}

          {/* 新高考 */}
          <Route path="/xingaokao" component={XinGaoKao} />
          {/* 摩阅图书馆 云图大数据 */}
          <Route path="/librarydata" component={LibraryData} />
          {/* 摩阅图书馆 服务 */}
          <Route path="/libraryservice" component={LibraryService} />
          {/* 云创大课堂 */}
          <Route path="/classroom" component={BigClassroom} />

          {/* 设置的弹出层 */}
          {createPortal(<Route component={SettingModal}></Route>, document.getElementById('root'))}
        </React.Fragment>
      </Router>
    </AppContainer>,
    document.getElementById("root")
  );
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
