/**
 *  Date    : 2018/9/18
 *  Author  : YP
 *  Declare : RealTime
 *
 */


import React from "react";

import Header from "../component/Header";
import Maps from "./Maps/Maps";
import UserReal from "./UserReal/UserReal"
import Operation from "./Operation/Operation"

import "./RealTime.scss";

class RealTime extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newPeople: {},
      isShow: false,
      showMask:false
    }
  }

  changePeople = (data) => {
    this.setState({
      newPeople: data,
    })
  };

  // componentDidMount(){
  //   const _this = this ;
  //   setTimeout(function(){
  //     _this.setState({showMask:false})
  //   },2000)
  // }

  render() {
    const { isShow,showMask } = this.state
    return (
      <div id='realTime'>
        <Header title={'实时行为动态'} text={'REAL TIME BEHAVIOR DYNAMICS'} />
        <div className='real-body'>
          <div className='box-left'>
            <Maps newPeople={this.state.newPeople} />
          </div>
          <div className='box-right'>
            <UserReal changePeople={this.changePeople} />
          </div>
        </div>
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow:!isShow
          })}></Operation>
        </div>
        {
          showMask && <div className='mask'><span>数据加载中</span></div>
        }
        
      </div>
    );
  }
}

export default RealTime;




