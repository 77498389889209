const isDevelopment = process.env.NODE_ENV !== 'production';

const hostIP = isDevelopment ? 'localhost' : window.location.hostname; // STEP2
const publicDomain = isDevelopment
  ? 'raysgo.com'
  : hostIP.substring(hostIP.indexOf('.') + 1);
const domainArr = ['chubanyun.me', 'raysgo.com', 'kingparkway.com', 'raysyun.com'];
const envArr = ['DEV', 'TEST', 'PRO', 'UAT'];
const envIndex = domainArr.findIndex(v => v === publicDomain); // 0开发环境，1测试环境，2生产环境, 3UAT环境;
const env = envArr[envIndex];
const agentIds = {
  'TEST': '1404',
  'UAT': '',
  'PRO': '1000024199'
}
const adviserIds = {
  TEST: 1001809,
  PRO: 1000024481
}
const config = {
  agentId: agentIds[env],
  adviserId: adviserIds[env],
  env
};

export default config;
