

import jinbaihuiApi from "@/api/jinbaihuiApi"
import React from "react"

// import Weather from '../newHome/components/wrather'
import Operation from "../RealTime/Operation/Operation"
import BookRecommend from "./components/BookRecommend/BookRecommend"
import BorrowList from "./components/BorrowList/BorrowList"
import ClassifyList from "./components/ClassifyList/ClassifyList"
import CountItem from "./components/CountItem/CountItem"
import HotWordList from "./components/HotWordList/HotWordList"
import PopularBook from "./components/PopularBook/PopularBook"
import ReaderCount from "./components/ReaderCount/ReaderCount"

import './index.scss'

class Page extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false,
      isLoading: true
    }
  }

  calculateCount = (num) => {
    if(num >= 10000) {
      return Math.round(num / 1000) / 10 + '万';
    }
    return num;
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://oss.5rs.me/oss/uploadfe/png/0b05793956f1e315d1ba8641c6a18972.png)`
  }

  componentWillMount() {
    jinbaihuiApi.displayLibrary().then(data => {
      const { library, borrowingRankingList, readerList, popularBooksList, libRecommendList, hotWordList, borrowingClassificationList } = data;
      this.setState({
        isLoading: false,
        library: library || {},
        borrowingRankingList,
        readerList,
        popularBooksList,
        libRecommendList,
        hotWordList,
        borrowingClassificationList
      })
    })
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://file.5rs.me/oss/uploadfe/png/f5ffd88849e964acf7367db90f431f85.png)`
  }

  render() {
    const {
      isShow, isLoading,
      library, borrowingRankingList, readerList, popularBooksList, libRecommendList,
      hotWordList, borrowingClassificationList
    } = this.state
    if(isLoading) return null;
    return (
      <div className="Library_tkyEkcEE">
        <div className="head">
          {/* <Weather /> */}
          <CountItem
            className="library-number"
            title="区域图书馆数"
            text={library.libraryNum}
          />
        </div>

        <div className="body">
          <div className="left-container">
            {/* 用户数据分析 */}
            <div className="user-data-wrap">
              {
                USER_DATA_LIST.map(item => {
                  const text = this.calculateCount(library[item.vKey]);
                  return (
                    <CountItem
                      className={`item_${item.key}`}
                      title={item.title}
                      text={text}
                      hightLight={item.hightLight}
                      key={item.key}
                    />
                  )
                })
              }
            </div>
            {/* 馆藏数据分析 */}
            <div className="library-data-wrap">
              {
                LIBRARY_DATA_LIST.map(item => {
                  const text = this.calculateCount(library[item.vKey]);
                  return (
                    <CountItem
                      className={`item_${item.key}`}
                      title={item.title}
                      text={text}
                      hightLight={item.hightLight}
                      key={item.key}
                    />
                  )
                })
              }
            </div>
            {/* 借阅排行 */}
            <div className="borrow-list-wrap">
              <BorrowList data={borrowingRankingList} />
            </div>
          </div>
          <div className="right-container">
            <div className="top-container">
              {/* 读者人数 */}
              <div className="reader-number-wrap">
              <ReaderCount list={readerList} />
              </div>
              {/* 新书推荐 */}
              <div className="new-book-wrap">
                <BookRecommend list={libRecommendList} />
              </div>
            </div>
            <div className="bottom-container">
              {/* 热门图书 */}
              <div className="hot-book-wrap">
                <div className="axis-title">(人/次)</div>
                <PopularBook list={popularBooksList} />
              </div>
              <div className="bottom-right-container">
                {/* 热词 */}
                <div className="hot-words-wrap">
                  <HotWordList list={hotWordList} />
                </div>
                {/* 借阅分类 */}
                <div className="book-classify-wrap">
                  <ClassifyList list={borrowingClassificationList} />
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    );
  }
}

const USER_DATA_LIST = [
  { key: 'today', vKey: 'dayBorrowers', title: '今日借阅人数', hightLight: true },
  { key: 'month', vKey: 'monthBorrowers', title: '本月借阅人数', hightLight: false },
  { key: 'totalReader', vKey: 'totalBorrowers', title: '总借阅人数', hightLight: false },
  { key: 'totalUser', vKey: 'totalUsers', title: '总用户数', hightLight: true }
]
const LIBRARY_DATA_LIST = [
  { key: 'today', vKey: 'todayBorrowedBooks', title: '今日借书册书', hightLight: true },
  { key: 'month', vKey: 'monthBorrowedBooks', title: '本月借书册书', hightLight: false },
  { key: 'totalReader', vKey: 'totalBorrowedBooks', title: '总借书册书', hightLight: false },
  { key: 'totalBook', vKey: 'totalBooks', title: '总馆藏数', hightLight: true }
]

export default Page;
