import React, { Component } from 'react'
import { Chart } from '@antv/g2';

export default class ReaderCount extends Component {

  initData(list) {
    let maxNum = 0;
    const data = list.map(item => {
      item.readerRegion = item.readerRegion.length > 5 ? item.readerRegion.slice(0, 4) + '···' : item.readerRegion
      if(item.readerNum > maxNum) maxNum = item.readerNum;
      return item;
    });
    const chart = new Chart({
      container: this.charRef,
      autoFit: true,
    });
    chart.data(data);
    chart.scale({
      readerNum: {
        max: maxNum * 1.2,
        min: 0,
        alias: '人数'
      },
    });
    // 坐标轴
    chart.axis('readerRegion', {
      title: null,
      tickLine: null,
      line: null,
      label: {
        style: {
          fill: '#fff'
        }
      }
    });
    chart.axis('readerNum', false);
    chart.legend(false);
    chart.coordinate().transpose();
    chart
      .interval()
      .position('readerRegion*readerNum')
      .size(26)
      .label('readerNum', {
        style: {
          fill: '#fff',
        },
        offset: 10,
      })
      .color('sales', (xVal) => {
        return 'l(0) 0:rgba(0,195,255,0.82) 1:rgba(16,108,152,0.15)';
      });
    ;
    // chart.interaction('element-active');
    chart.render();
  }
  
  componentDidMount() {
    const { list = [] } = this.props;
    this.initData(list.slice(0, 6));
  }
  
  render() {
    return (
      <div className='ReaderCount' style={{ height: '90%'}} ref={r => this.charRef = r}></div>
    )
  }
}


