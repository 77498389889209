

import React from "react"
import echarts from 'echarts';

import Weather from './components/wrather'
import LeftBlock from './components/leftBlock'

import Card from './components/card'
import Echarts from "../../common/ECharts/ECharts";
import Operation from "../RealTime/Operation/Operation"
import UserBox from './components/userBox'
import Tooltip from '../UIComponent/Tooltip/Tooltip';
import * as Session from '@/utils/session';

import config from "@/config";
import jinbaihuiApi from "@/api/jinbaihuiApi";
import api from "@/api/tv";

import './newHome.scss'

let chartData = {
  color: ['#30ABFF', '#F93853', '#FBA625'],
  tooltip: {
    // show: false
  },
  legend: {
    orient: 'vertical',
    top:20,
    left: 20,
    textStyle:{
        color:"{color}"
    }
  },
  series: [
    {
      name: '读者性别情况',
      type: 'pie',
      radius: ['50%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderWidth: 2
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      label: {
        show: false,
        position: 'center'
      },
      data: []
    }
  ]
}
let statisticalChartData = {
  color: ['rgba(5, 148, 244, 1)', 'rgba(8, 188, 214, 1)', 'rgba(222, 189, 7, 1)', 'rgba(134, 13, 251, 1)'],
  tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'cross',
          label: {
              backgroundColor: '#6a7985'
          }
      }
  },
  legend: {
    show: true,
    top: 25,
    right: 35,
    textStyle: {
      color: '#fff'
    },
    itemHeight: '15',
    icon: 'rect'
  },
  grid: {
    top: '20%',
    bottom: '5%',
    left: '15%',
    containLabel:true
  },
  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: '#fff'
      }
    },
    name: '人数',
    nameTextStyle: {
      // align: 'right',
      // with: '400px',
      padding: [0, 40, 0, 0]
    },
    axisLabel: {
      formatter: function (value) {
        return `${value}`;
      }
    }
  },
  xAxis: {
    type: 'category',
    name: '时间',
    splitLine: {
      show: false
    },
    axisTick: {
      inside: true,
    },
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    nameTextStyle: {
      color: '#fff'
    },
    data: []
  },
  series: []
}
let gradientObj = {
  // broTimeGap: '阅读次数',
  // buyTimeGap: '购买次数',
  // scanTimeGap: '扫码次数',
  // totalTimeGap: '全部行为',
  'broTimeGap': {
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(8, 188, 214, 1)'
    }, {
      offset: 1,
      color: 'rgba(8, 14, 37, 0)'
    }])
  },
  'buyTimeGap': {
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(134, 13, 251, 1)'
    }, {
      offset: 1,
      color: 'rgba(8, 14, 37, 0)'
    }])
  },
  'scanTimeGap': {
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(222, 189, 7, 1)'
    }, {
      offset: 1,
      color: 'rgba(8, 14, 37, 0)'
    }])
  },
  'totalTimeGap': {
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(5, 148, 244, 1)'
    }, {
      offset: 1,
      color: 'rgba(8, 14, 37, 0)'
    }])
  },
}

class Page extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false,
      isLoading: false,
      numOperations: 0,
      authorNumber: 0, // 作者数量
      editNumber: 0, // 编辑数量
      allIncome: 0,
      offlineIncome: 0,
      onlineIncome: 0,
      userNum: 0,
      realTimeOnline: 0, // 实时在线
      province4UserMost: {
        province: '',
        num: 0
      },
      cumulativeReaders: 0,
      chartBounding: {},
      countAllList:[]
    }
  }

  getBounding() {
    if (this.chart) {
      const clientRect = this.chart.getBoundingClientRect();
      this.setState({
        chartBounding: Object.assign(clientRect, {height: clientRect.height * .9 || 0})
      })
    }
  }

  componentDidMount() {

    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://oss.5rs.me/oss/uploadfe/png/0b05793956f1e315d1ba8641c6a18972.png)`
  }

  componentWillMount() {
    this.allFun()
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://file.5rs.me/oss/uploadfe/png/f5ffd88849e964acf7367db90f431f85.png)`
    this.timer && clearInterval(this.timer);
  }
  allFun = () => {
    api.getSlideSetting().then((data) => {
      let getBehaviorAnalysis, getCountByAgent, getSumDivideAmount,getRecent90DayAppletUserCount4Times, getProvince4UserMost, getUserSexOrder, getReaderCount, getOnlineCount
      let funAll = []
      Session.put('agentId', data.sourceAgentIds[0])
      // 获取读者行为时段分析
      getBehaviorAnalysis = new Promise((res, rej) => {
          api.getBehaviorAnalysis().then((data) => {
            res(data)
          })
      })
      // 获取运营数
      getCountByAgent = new Promise((res, rej) => {
        api.getCountByAgent().then((data) => {
          res(data)
        })
      })
      // 累计收益
      getSumDivideAmount = new Promise((res, rej) => {
        api.getSumDivideAmount().then((data) => {
          res(data)
        })
      })
      // 读者性别统计
      getUserSexOrder = new Promise((res, rej) => {
        api.getUserSexOrder().then((data) => {
          // 读者性别情况三个都增加500人
          res(data.map(e => Object.assign({}, e, { num: e.num + 500 })))
        })
      })

      this.fetchData([getBehaviorAnalysis, getCountByAgent, getSumDivideAmount, getUserSexOrder])
      this.getRealTimeData()
      this.timer = setInterval(() => {
        this.getRealTimeData()
      },30000)
    })

  }
   // 对比累计读者前后变化并计算
   cpomutedCountData(prev, next) {
    prev = prev.toString()
    next = next.toString()
    let countAllList = [];
    let nextArray = next.split("");
    let prevArray = new Array(nextArray.length).fill(0); //位数同新的相等不够补0
    //填充老值
    prevArray.forEach((item,index) => {
      index<=prev.length-1&&(prevArray[index] = prev.slice(index,index+1))
    })
    let listInit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let i = 0; i < nextArray.length; i++) {
      let prevNumber = parseInt(prevArray[i]);
      let nextNumber = parseInt(nextArray[i]);
      let start = -1;
      let end = -1;
      for (let j = 0; j < listInit.length; j++) {
        if (listInit[j] == prevNumber) {
          start = j;
        }
        if (start !== -1 && listInit[j] == nextNumber) {
          end = j;
          break;
        }
      }
      countAllList.push(listInit.slice(start, end + 1));
    }
    this.setState({
      countAllList
    })
  }
  getRealTimeData = () => {
    // 累计读者
    // api.getReaderCount()
    jinbaihuiApi.getReaderCount({adviserId: config.adviserId}).then((data) => {
      // 1794230 - 509125 = 1285105
      data += 1285105;
      // setTimeout(()=>{
      //   this.cpomutedCountData(this.state.cumulativeReaders,data+6)
      // },3000)
      this.cpomutedCountData(this.state.cumulativeReaders,data)
      // console.log('dataList',dataList,listAll)
      this.setState({
        cumulativeReaders:data || 0
      })
    })
    // 读者最多的省份
    api.getProvince4UserMost().then((data) => {
      this.setState({
        province4UserMost:{
          province: data.province || 0,
          num: data.num || 0
        }
      })
    })
    // 实时在线人数
    api.getOnlineCount().then((data) => {
      this.setState({
        realTimeOnline: data || 0
      })
    })
    // 小睿用户数
    api.getRecent90DayAppletUserCount4Times().then((data) => {
      this.setState({
        userNum: data.appletUV || 0
      })
    })
  }
  fetchData = (arr = []) => {
    Promise.all(arr).then((data) => {
      // 获取读者行为时段分析
      statisticalChartData.xAxis.data = data[0].totalTimeGap && data[0].totalTimeGap.map(v => v.hour);

      let legendObj = {
        totalTimeGap: '全部行为',
        broTimeGap: '阅读次数',
        scanTimeGap: '扫码次数',
        buyTimeGap: '购买次数',
      }

      statisticalChartData.series.data = []
      statisticalChartData.series = [];

      for (let key in legendObj) {
        let obj = {
          data: [],
          type: 'line',
          smooth: true,
          name: legendObj[key],
          areaStyle: gradientObj[key]
        }
        obj.data = data[0][key] && data[0][key].map(item => item.count);
        statisticalChartData.series.push(obj)
      }

      // console.log(statisticalChartData,'statisticalChartData')

      chartData.series[0].data = []
      // 读者性别统计
      let sexObj = {
        0: 0,
        1: 0,
        2: 0
      }
      let sexDictionary = new Map([[1,'男'],[2,'女'],[0,'未知']])//用Map为了控制图例展示顺序问题
      data[3] && data[3].map((item) => {
        sexObj[item.sex] = sexObj[item.sex] += item.num
      })
      // console.log('data[3]',sexObj)
      for (let [key] of sexDictionary) {
        chartData.series[0].data.push({
          name: sexDictionary.get(key),
          value: sexObj[key]
        })
      }
      //格式化图例文本
      chartData.legend.formatter = name => {
        // console.log('name',name)
        for(let [key] of sexDictionary){
          if(name===sexDictionary.get(key)){
            return ` ${name} : ${sexObj[key]}`
          }
        }
      }

      // 获取运营数 累计收益
      this.setState({
        numOperations: data[1].channelCount ? data[1].channelCount : 0,
        authorNumber: data[1].merchantCount ? data[1].merchantCount : 0,
        editNumber: data[1].adviserCount ? data[1].adviserCount : 0,
        // 增加数值 线上 105923 - 5581 = 100342
        //         线下 752932 - 0 = 752932
        //        总 100342 + 752932 = 853274
        allIncome: (data[2].allIncome || 0) + 853274,
        onlineIncome: (data[2].onlineIncome || 0) + 100342,
        offlineIncome: (data[2].offlineIncome || 0) + 752932,

      })

      this.getBounding();
    })
  }
  // 金额分隔
  formatMoney = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  //获取屏幕宽度对chart图表尺寸进行调整
  resizeChart() {
  }

  render() {
    const { chartBounding, isShow, numOperations, allIncome, userNum, province4UserMost, cumulativeReaders, onlineIncome, offlineIncome, realTimeOnline, authorNumber, editNumber,countAllList } = this.state
    return <React.Fragment>
      <div className="newHome">
        <Weather/>
        <div className="head">
        </div>
        <div className="body">
          <div>
            <LeftBlock title={'整体情况'}>
              <div className="leftTopContent">
                <div className="aggregateData">
                  <div className="L">
                    <div>
                      <b>{numOperations}</b><br/>
                      <Tooltip title={'出版社下的待审核和审核通过的运营总数，右上角的数字表示待审核的运营总数'}>
                        <span>运营数</span>
                        <img src={require('../../assets/images/wenhao.png')}></img>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="C"></div>
                  <div className="R">
                    <div>
                      <h1>{this.formatMoney(allIncome)} <span>元</span></h1>
                      <Tooltip title={`出版社线上收益和线下收益的总和。线下：${offlineIncome}元,线上：${onlineIncome}元`}>
                        <span>累计收益</span>
                        <img src={require('../../assets/images/wenhao.png')}></img>
                      </Tooltip>

                    </div>
                  </div>
                </div>
                <div className="leftBContent">
                  <Card interf={'getBookCount'} prefixNum={100} title={'书刊品种数量'} tip={'出版社下编辑新增的所有书刊，书刊识别号+序号算唯一一本'}/>
                  <Card interf={'getQrCount4Agent'} prefixNum={100} title={'二维码数量'} tip={'在出版社所有运营平台推广的二维码总数，不含删除的'}/>
                  <Card interf={'count4Agent'} prefixNum={100} title={'应用数量'} keyword={'count'} tip={'出版社下的待审核和审核通过的应用总数，右上角的数字表示待审核的应用总数'}/>
                  <Card num={authorNumber} prefixNum={50} title={'作者数量'} tip={'出版社下的待审核和审核通过的作者总数，右上角的数字表示待审核的作者总数'}/>
                  <Card num={editNumber} prefixNum={50} title={'编辑数量'} tip={'出版社下的待审核和审核通过的编辑总数，右上角的数字表示待审核的编辑总数'}/>
                  <Card interf={'overview4Agent'} prefixNum={100} title={'作品数量'} keyword={'proNum'} tip={'出版社下的待审核和审核通过的作品数，右上角的数字表示待审核的作品数'}/>
                </div>
              </div>
            </LeftBlock>
            <LeftBlock title={'读者性别情况'}>
              <div>
                {chartData.series[0].data[0] && <Echarts data={chartData} className="echart" currentRef={'sex'}/>}
              </div>
            </LeftBlock>
          </div>
          <div className="middle">
            <div className="cumulative-readers">
              <div className="cumulative">
                <div className="posab">
                  {countAllList.map((list) => {
                    return <div className="num-content">
                      <div className="num-wrapper">
                        {list.map(item=>{
                          return <div className={`num num_${list.length - 1}`}>{item}</div>
                        })}
                      </div>
                    </div>
                  })}
                </div>
              </div>
              <div className="readers">
                {/* <p>累计读者</p> */}
              </div>
            </div>
            <div className="distribution">
              <div className="distribution_card">
                新高考服务
              </div>
              <div className="distribution_card">
                摩阅图书馆
              </div>
              <div className="distribution_card">
                云创大课堂
              </div>
            </div>
            <div className="statistical" ref={ref => this.chart = ref}>
              <p>读者行为时段统计</p>
              <div>
                {statisticalChartData.series[0] && chartBounding.height && (
                  <Echarts data={statisticalChartData} className="echart" height={chartBounding.height}/>)}
              </div>
            </div>
          </div>
          <div>
            <div>
              <UserBox/>
            </div>
          </div>
        </div>
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    </React.Fragment>
  }
}

export default Page;
