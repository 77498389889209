// https://g2.antv.vision/en/examples/other/other#word-cloud

import React, { Component } from 'react'
import DataSet from '@antv/data-set';
import { Chart, registerShape, Util } from '@antv/g2';

export default class HotWordList extends Component {

  initData(list) {
    list = list.map((item) => ({
      x: item.word,
      value: item.id
    }));
    const { width, height } = this.chartRef.getBoundingClientRect();

    function getTextAttrs(cfg) {
      return {
        ...cfg.defaultStyle,
        ...cfg.style,
        fontSize: cfg.data.size,
        text: cfg.data.text,
        textAlign: 'center',
        fontFamily: cfg.data.font,
        fill: cfg.color || cfg.defaultStyle.stroke,
        textBaseline: 'Alphabetic'
      };
    }

    // 给point注册一个词云的shape
    registerShape('point', 'cloud', {
      draw(cfg, container) {
        const attrs = getTextAttrs(cfg);
        const textShape = container.addShape('text', {
          attrs: {
            ...attrs,
            x: cfg.x,
            y: cfg.y
          }
        });
        if (cfg.data.rotate) {
          Util.rotate(textShape, cfg.data.rotate * Math.PI / 180);
        }
        return textShape;
      }
    });

    const dv = new DataSet.View().source(list);
    dv.transform({
      type: 'tag-cloud',
      fields: ['x', 'value'],
      size: [width, height],
      font: 'Verdana',
      padding: 0,
      timeInterval: 5000, // max execute time
      rotate() {
        let random = ~~(Math.random() * 4) % 4;
        if (random === 2) {
          random = 0;
        }
        return random * 90; // 0, 90, 270
      },
      fontSize(d) {
        return (Math.random() * 10) + 10
      },
    });
    const chart = new Chart({
      container: this.chartRef,
      autoFit: false,
      width: width,
      height: height,
      padding: 0
    });
    chart.data(dv.rows);
    chart.scale({
      x: { nice: false },
      y: { nice: false },
      value: { alias: '次数'}
    });
    chart.legend(false);
    chart.axis(false);
    chart.tooltip({
      showTitle: false,
      showMarkers: false
    });
    chart.coordinate().reflect();
    chart.point()
      .position('x*y')
      .color('text') // 
      .shape('cloud')
      .tooltip('value*category');
    chart.interaction('element-active');
    chart.render();
  }
  
  componentDidMount() {
    const { list = []} = this.props;
    this.initData(list);
  }
  
  render() {
    return (
      <div className='HotWordList' style={{ height: '100%' }} ref={r => this.chartRef = r}></div>
    )
  }
}
