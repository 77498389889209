import React, { useEffect, useState } from 'react'

import './BookRecommend.scss';

export default function BookRecommend(props) {

  const { list = [] } = props;

  const [bookList, setBookList] = useState([]);

  useEffect(() => {
    let timer = null, len = 0;
    if(list.length > BOOK_LEN) {
      setBookList(list.slice(0, BOOK_LEN * 2));
      timer = setInterval(() => {
        len += BOOK_LEN;
        if(len >= list.length) len = 0;
        setBookList(list.slice(len, len + BOOK_LEN * 2));
      }, 3 * 1000);
    } else {
      setBookList(list);
    }
    return () => {
      timer && clearInterval(timer);
    }
  }, [list]);
  
  return (
    <div className='BookRecommend' key={bookList}>
      {
        bookList.map(item => {
          return (
            <div className='book-item-wrap' key={item.id}>
              <div className='book-img' style={{ backgroundImage: `url(${item.img})`}} />
            </div>
          )
        })
      }
    </div>
  )
}
const BOOK_LEN = 3;