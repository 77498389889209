import React from 'react'

import './CountItem.scss';

export default function CountItem(props) {

  const { title, text, hightLight, className } = props;
  
  return (
    <div className={`CountItem_zqTzZz00 ${className}`}>
      <div className='title-content'>{title}</div>
      <div className='text-wrap'>
        <div className={`text-content ${hightLight ? 'hight-light' : ''}`}>{text}</div>
      </div>
    </div>
  )
}
