import React, { useEffect, useRef, useState } from 'react'

import './WordLineList.scss';

export default function WordLineList(props) {

  const { list = [], getWordFn, length } = props;
  
  const [wordList, setWordList] = useState([]);

  const timerRef = useRef(null);
  const countRef = useRef(0);
  
  useEffect(() => {
    if(!length || list.length <= length) {
      setWordList(list.map(v => ({ sequence: v.sequence, content: getWordFn(v) })));
    } else {
      setWordList(
        list
          .slice(countRef.current, countRef.current + length)
          .map(v => ({ sequence: v.sequence, content: getWordFn(v) }))
      )
      timerRef.current = setInterval(() => {
        countRef.current++ // = countRef.current + length
        if(countRef.current > list.length) countRef.current = 0;
        let arr = list.slice(countRef.current, countRef.current + length);
        if(arr.length < length) {
          arr = [].concat(arr, list).slice(0, length);
        }
        setWordList(
          arr
            // .slice(countRef.current, countRef.current + length)
            .map(v => ({ sequence: v.sequence, content: getWordFn(v) }))
        )
      }, INTERVAL_TIME);
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current);
    }
  }, [list, length, getWordFn]);
  
  return (
    <div className='WordLineList'>
      {
        wordList.map((v, index) => {
          return (
            <div className='word-item' key={index}>
              {/* <span>（{v.sequence}）</span> */}
              <span title={v.content}>{v.content}</span>
            </div>
          )
        })
      }
    </div>
  )
}
const INTERVAL_TIME = 1 * 1000;