/**
 *  Date    : 2019/8/9
 *  Author  : CastileMan
 *  Declare : Tooltip
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import './Tooltip.scss';

const body = document.body;

const PLACEMENT = {
  TOP: 'TOP',
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  LEFT: 'LEFT',
  LEFT_TOP: 'LEFT_TOP',
  LEFT_BOTTOM: 'LEFT_BOTTOM',
  RIGHT: 'RIGHT',
  RIGHT_TOP: 'RIGHT_TOP',
  RIGHT_BOTTOM: 'RIGHT_BOTTOM',
  BOTTOM: 'BOTTOM',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};

class Tooltip extends React.PureComponent {
  static defaultProps = {
    className: '',                 // 自定义类名
    popupParentEl: body,
    placement: PLACEMENT.BOTTOM_RIGHT,   // 提示展示的方位
    maxWidth: 300,                 // 提示的最大宽度
    distance: 15,                  // 提示距离 trigger 的距离
    threshold: 20,                  // 提示距离屏幕的最小距离
    title: '',                      // 提示的内容
    hideOnClick: false,             // 当点击 trigger 时隐藏提示
    disabled: false                 // 禁用提示
  };

  static PLACEMENT = PLACEMENT;

  constructor(props) {
    super(props);
    this.state = {
      status: STATUS.HIDDEN,
      position: {
        left: 0,
        top: 0,
        transformOrigin: '0% 0%'
      },
      scale: 1
    };
    this.container = null;
    this.titleElSize = null;
    this.timer = null;
    this.unmounted = false;
  }

  init() {
    const { popupParentEl } = this.props;
    this.container = document.createElement('div');
    this.container.className = `ToolTipContainer ${STATUS.HIDDEN}`;
    popupParentEl.appendChild(this.container);
    this._renderTitle();
  }

  show = () => {
    const { title, disabled } = this.props;
    const { status } = this.state;
    this.clearTimer();
    if(disabled || status === STATUS.SHOW || !title) return;
    this.updatePosition();
    // add scale animation
    this.setState({
      scale: 0
    }, () => {
      window.setTimeout(() => {
        this.container.classList.remove(STATUS.HIDDEN);
        this.setState({
          scale: 1,
          status: STATUS.SHOW
        });
      }, 50);
    });
  };

  onClick = () => {
    const { hideOnClick } = this.props;
    if(hideOnClick) {
      this.hide();
    }
  };

  hide = () => {
    this.container.classList.add(STATUS.HIDDEN);
    this.setState({
      status: STATUS.HIDDEN
    });
  };

  // 尝试在一段时间后隐藏提示
  tryHide = () => {
    this.clearTimer();
    this.timer = window.setTimeout(() => {
      if(this.unmounted) return;
      this.hide();
    }, 200);
  };

  clearTimer() {
    if(this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  updatePosition() {
    const { distance, threshold, placement } = this.props;
    const triggerRect = this.triggerEl.getBoundingClientRect();
    const titleRect = this.titleEl.getBoundingClientRect();
    // cache the size of title
    if(!this.titleElSize) {
      this.titleElSize = {
        width: titleRect.width,
        height: titleRect.height
      };
    }
    // 根据站位的方向，进行对应方位的计算
    const formatter = getPlacementFormatter(placement);
    // calculate left & top
    let left = formatter.left(triggerRect, this.titleElSize, distance);
    left = Math.min(left, body.offsetWidth - this.titleElSize.width - threshold);
    left = Math.max(left, threshold);
    let top = formatter.top(triggerRect, this.titleElSize, distance);
    top = Math.min(top, body.offsetHeight - this.titleElSize.height - threshold);
    top = Math.max(top, threshold);

    // calculate transform origin
    let originX = (triggerRect.left + triggerRect.width / 2 - left) / this.titleElSize.width;
    originX = Math.max(Math.round(originX * 100), 0);
    let originY = (triggerRect.top + triggerRect.height / 2 - top) / this.titleElSize.height;
    originY = Math.max(Math.round(originY * 100), 0);
    const transformOrigin = `${originX}% ${originY}%`;

    this.setState({
      position: {
        left,
        top,
        transformOrigin
      }
    });
  }

  destroy() {
    this.container.parentElement.removeChild(this.container);
    this.clearTimer();
    this.unmounted = true;
  }

  // simulate ReactDOM.createPortal with React v15.6
  _renderTitle() {
    const { title, maxWidth } = this.props;
    const { position: { left, top, transformOrigin }, scale } = this.state;
    if(this.container) {
      ReactDOM.render(
        <div
          ref={ref => this.titleEl = ref}
          className="tool-tip-inner"
          onMouseEnter={this.show}
          onMouseLeave={this.tryHide}
          style={{
            left,
            top,
            width: this.titleElSize ? this.titleElSize.width : null,
            transform: `scale(${scale})`,
            transformOrigin,
            maxWidth
          }}
        >
          {title}
        </div>,
        this.container
      );
    }
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    this._renderTitle();
  }

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    const { className } = this.props;
    const { status } = this.state;
    return (
      <div
        ref={ref => this.triggerEl = ref}
        className={classNames('Tooltip', status, className)}
        onMouseEnter={this.show}
        onMouseLeave={this.tryHide}
        onClick={this.onClick}
      >
        {this.props.children}
      </div>
    );
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.oneOf(Object.values(PLACEMENT)),
  hideOnClick: PropTypes.bool,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.number,
  distance: PropTypes.number,
  threshold: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]).isRequired
};

const STATUS = {
  SHOW: 'SHOW',
  HIDDEN: 'HIDDEN'
};

const getPlacementFormatter = (placement = PLACEMENT.BOTTOM) => {
  let leftFormatter = null;
  let topFormatter = null;
  switch (placement) {
    case PLACEMENT.BOTTOM:
      leftFormatter = (triggerRect, titleElSize) => triggerRect.left + triggerRect.width / 2 - titleElSize.width / 2;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.bottom + distance;
      break;

    case PLACEMENT.BOTTOM_LEFT:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.right - titleElSize.width + distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.bottom + distance;
      break;

    case PLACEMENT.BOTTOM_RIGHT:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.left - distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.bottom + distance;
      break;

    case PLACEMENT.RIGHT:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.right + distance;
      topFormatter = (triggerRect, titleElSize) => triggerRect.top + triggerRect.height / 2 - titleElSize.height / 2;
      break;

    case PLACEMENT.RIGHT_TOP:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.right + distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.bottom - titleElSize.height + distance;
      break;

    case PLACEMENT.RIGHT_BOTTOM:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.right + distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.top - distance;
      break;

    case PLACEMENT.LEFT:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.left - titleElSize.width - distance;
      topFormatter = (triggerRect, titleElSize) => triggerRect.top + triggerRect.height / 2 - titleElSize.height / 2;
      break;

    case PLACEMENT.LEFT_TOP:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.left - titleElSize.width - distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.bottom - titleElSize.height + distance;
      break;

    case PLACEMENT.LEFT_BOTTOM:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.left - titleElSize.width - distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.top - distance;
      break;

    case PLACEMENT.TOP:
      leftFormatter = (triggerRect, titleElSize) => triggerRect.left + triggerRect.width / 2 - titleElSize.width / 2;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.top - titleElSize.height - distance;
      break;

    case PLACEMENT.TOP_LEFT:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.right - titleElSize.width + distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.top - titleElSize.height - distance;
      break;

    case PLACEMENT.TOP_RIGHT:
      leftFormatter = (triggerRect, titleElSize, distance) => triggerRect.left - distance;
      topFormatter = (triggerRect, titleElSize, distance) => triggerRect.top - titleElSize.height - distance;
      break;
  }

  return {
    left: leftFormatter,
    top: topFormatter
  };
};

export default Tooltip;
