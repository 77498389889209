/**
 *  Date    : 2018/10/28
 *  Author  : swq
 *  Declare : Operation
 *
 */

import React from "react";
import api from "@/api/tv";
import * as Session from '@/utils/session';

import "./Operation.scss";

class Operation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newPeople: {},
    }
    this.flag = true
    this.routeList = []
  }

  changePeople = (data) => {
    this.setState({
      newPeople: data,
    })
  };

  settingClick = (e, index) => {
    if (index === 2) {
      //点击设置弹窗
      if (document.querySelector('#SettingModal-box').style.visibility === 'initial') {
        document.querySelector('#SettingModal-box').style.visibility = 'hidden'
      } else {
        document.querySelector('#SettingModal-box').style.visibility = 'initial'
      }

    } else if (index === 0) {
      this.routeTo('pre')
      //点击上一级路由
      // let currentRoute = ROUTES_LIST.filter((v)=>v.route===pathname)
    } else if (index === 1) {
      this.routeTo('next')
    }else if(index===3){
      //当前页面只设置了一个的情况下，在设置其他页面需要刷新
      window.location.reload();
    }
    this.flag = false
  }

  onClickItem = (item) => {
    const { history } = this.props
    history.replace(item.route);
  }

  sort = (arr) => {
    arr.sort((a, b) => {
      return a - b
    })
  }

  routeTo = (params) => {
    const { location: { pathname }, history } = this.props
    let currentRoute = JSON.parse(Session.get('routeList')).filter((v) => v.route === pathname)
    const route_list = JSON.parse(Session.get('routeList'))
    if (route_list.length === 1) return
    if (!currentRoute.length) {
      //若当前处在当前页，平台端大屏设置取消了该页面，则下一页随机在剩下的页面中去随机整数跳转
      history.replace(`${route_list[Math.floor(Math.random() * (route_list.length - 0)) + 0].route}`)
    } else {
      let currentRouteId = route_list.filter((v) => v.id === currentRoute[0].id)[0].id
      let currentRouteIndex = route_list.findIndex((item) => item.id === currentRouteId)
      if (params === 'next') {
        if (currentRoute[0].id === route_list[route_list.length - 1].id) {
          history.replace(`${route_list[0].route}`)
        } else {
          history.replace(route_list[currentRouteIndex + 1].route)
        }
      } else {
        if (currentRoute[0].id === route_list[0].id) {
          history.replace(`${route_list[route_list.length - 1].route}`)
        } else {
          history.replace(route_list[currentRouteIndex - 1].route)
        }
      }
    }
  }

  componentDidMount = () => {
    const { location: { pathname }, history } = this.props
      // api.getScreenShow().then((data) => {
      //   for (const key in data) {
      //     if (data[key] && ROUTES_LIST.filter(v => v.key === key).length) {
      //       this.routeList.push(ROUTES_LIST.filter(v => v.key === key)[0])
      //     }
      //   }
      //   Session.put('routeList',JSON.stringify(this.routeList))
      //   if(!JSON.parse(Session.get('routeList')).map((item)=>item.route).includes(pathname)){
      //     history.replace(JSON.parse(Session.get('routeList'))[0].route)
      //   }
      // })
      Session.put('routeList',JSON.stringify(ROUTES_LIST))
      if(!JSON.parse(Session.get('routeList')).map((item)=>item.route).includes(pathname)){
        history.replace(JSON.parse(Session.get('routeList'))[0].route)
      }
  }

  render() {
    return (
      <div id='Operation'>
        {
          ICON_NAME.map((v, index) => {
            const showJumpPage = ['iconjiantouleft', 'iconjiantouright'].includes(v.icon)
            return (
              <div
                className={`operation-item item-${v.icon}`}
                onClick={(e) => this.settingClick(e, index)}
                title={v.title}
                key={index}
              >
                <i className={`iconfont ${v.icon}`}></i>
                {
                  showJumpPage &&
                  <div className="jump-wrap" onClick={preventDefault}>
                    {
                      ROUTES_LIST.map(item => {
                        return (
                          <div
                            className="page-item"
                            title={item.title}
                            onClick={() => this.onClickItem(item)}
                            key={item.key}
                          >{item.title}</div>
                        );
                      })
                    }
                  </div>
                }
              </div>
            )
          })
        }
      </div>
    );
  }
}

export default Operation;

function preventDefault(event) {
  event.preventDefault();
  event.stopPropagation();
  return false;
}

const ICON_NAME = [
  { title: '上一页', icon: 'iconjiantouleft'},
  { title: '下一页', icon: 'iconjiantouright'},
  { title: '设置', icon: 'iconshezhi'},
  { title: '刷新', icon: 'iconshuaxin'}
]

const ROUTES_LIST = [
  // { key: 'homePageShow', id: 1, route: '/' },//homePageShow （首页）
  { key: 'homePageShow', id: 1, route: '/newHome', title: '云汇大数据运营视窗' },//homePageShow （首页）
  { key: 'dynamicShow', id: 5, route: '/realtime', title: '实时行为动态' },// 实时行为动态
  { key: 'incomeOverviewShow', id: 2, route: '/data', title: '收益总览' },//incomeOverviewShow （收益总览）
  { key: 'productExperienceShow', id: 3, route: '/product', title: '产品体验' },//productExperienceShow （产品体验）
  // { key: 'userOverviewShow', id: 4, route: '/user' },//userOverviewShow （用户总览）
  // { key: 'groupDataShow', id: 6, route: '/community' }, //groupDataShow （社群数据）
  { key: 'xinGaoKao', id: 7, route: '/xingaokao', title: '云评联考服务智慧视窗' }, // 新高考
  { key: 'LibraryData', id: 8, route: '/librarydata', title: '摩阅智慧图书馆'}, // 摩阅图书馆1 云图大数据
  { key: 'LibraryService', id: 9, route: '/libraryservice', title: '摩阅智慧图书馆'}, // 摩阅图书馆2
  { key: 'BigClassroom', id: 10, route: '/classroom', title: '金百汇云创大课堂'}, // 云创大课堂
]


