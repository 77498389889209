import React, { useEffect, useState } from 'react'

import './SampleCaseList.scss';

export default function SampleCaseList(props) {

  const { list = []} = props;

  const [caseList, setCaseList] = useState([]);

  useEffect(() => {
    let currentIndex = 0;
    setCaseList([].concat(list.slice(-1), list.slice(0, 2)));
    let timer = setInterval(() => {
      currentIndex++;
      if(currentIndex > list.length - 1) currentIndex = 0;
      let cList = [];
      if(currentIndex === 0) {
        cList = [].concat(list.slice(-1), list.slice(0, 2));
      } else if(currentIndex === list.length - 1) {
        cList = [].concat(list.slice(-2), list.slice(0, 1));
      } else {
        cList = list.slice(currentIndex - 1, currentIndex + 2);
      }
      setCaseList(cList);
    }, 2000);
    return () => {
      timer && clearInterval(timer);
      timer = null;
    }
  }, [list]);

  return (
    <div className='SampleCaseList'>
      {
        caseList.map((item, index) => {
          return (
            <div className={`list-item-wrap item_${index}`}>
              <div className='list-item' style={{ backgroundImage: `url(${item.img})`}} />
            </div>
          )
        })
      }
    </div>
  )
}
