/**
 *  Date    : 2018/9/12
 *  Author  : Zhu Shupei
 *  Declare : Product.js
 *
 */


import React from "react";
import api from "@/api/tv";

import Header from "../component/Header"
import Operation from "../RealTime/Operation/Operation"

import "./Product.scss";
import "../component/Header.scss"

class Product extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      active: 0,//点击时记录状态
      add: '',  //地址
      isShow: false,
      appExamplePicList: [],
      appCodePicList: [],
      groupCodePicList: [],
      groupExamplePicList: [],
      typecode: 'app',
      leftImg: '',
      leftCode: '',
      rightCode: '',
      rightImg: ''
    };
    this.appTimer = ''
    this.groupTime = ''
    this.changeState = this.changeState.bind(this);
  }

  //应用扫码体验按钮
  changeState(e) {
    if (e) {
      this.setState({ active: 0 });
    } else {
      this.setState({ active: 1 });
    }
  }

  changePictureApp = () => {
    const { appExamplePicList } = this.state
    let timer, timeout = 8000, i = 0;
    const getPicture = () => {
      
      clearTimeout(timer);
      this.setState({
        add: appExamplePicList[i],
      });
      i++;
      if (i > appExamplePicList.length - 1) {
        i = 0;
      }
      if(appExamplePicList.length<=1) return false
      timer = setTimeout(getPicture, timeout);
    };
    getPicture();
  }

  changeImg(n) {
    const { leftImg, rightImg } = this.state
    let timer, timeout = 8000, i = 0;
    const getPicture = () => {
      clearTimeout(timer);
      if (n === 'personal' && leftImg.length > 1) {
        this.setState({
          leftImg: leftImg[i],
        });
        i++;
        if (i > leftImg.length-1) {
          i = 0;
        }
      }
      if (n === 'common' && rightImg.length > 1) {
        this.setState({
          rightImg: rightImg[i],
        });
        i++;
        if (i > rightImg.length-1) {
          i = 0;
        }
      }
      timer = setTimeout(getPicture, timeout);
    };
    getPicture();
  }

  fetchData = () => {
    api.getAppCodeInfo({}).then((data = []) => {
      let appExamplePicList = [];
      const appCodePicList = data.map(item => {
        item.caseUrl && appExamplePicList.push(item.caseUrl);
        return {
          appName: OPTIONS[item.typeCode],
          codeUrl: item.experienceCode,
          // caseUrl: item.caseUrl
        }
      })
      this.setState({
        appExamplePicList,
        appCodePicList
      })
      api.getBookCodeInfo({}).then((data) => {
        this.setState({
          groupExamplePicList: data.commonGroupBook.caseUrls.concat(data.personalGroupBook.caseUrls),
          groupCodePicList: [{ id: 0, className: 'left-phone', src: 'https://file.5rs.me/oss/uploadfe/png/55ed18a47e3739dc4930597a4bf6b8f2.png' }, { id: 1, className: 'right-phone', src: 'https://file.5rs.me/oss/uploadfe/png/55ed18a47e3739dc4930597a4bf6b8f2.png', appName: '个人号社群书体验码' }],
          leftImg: data.personalGroupBook.caseUrls,
          leftCode: data.personalGroupBook.experienceCode,
          rightCode: data.commonGroupBook.experienceCode,
          rightImg: data.commonGroupBook.caseUrls,
        }, () => {
          this.changePictureApp()
          this.changeImg('personal')
          this.changeImg('common')
        })
      })
    })
  }

  componentDidMount() {
    this.fetchData()

  }

  render() {
    const state = this.state.active;
    const { isShow, appCodePicList, groupCodePicList, leftImg, rightImg, leftCode, rightCode,add ,appExamplePicList} = this.state
    return (
      <div id="Product">
        <Header title={'产品体验'} text={'PRODUCT EXPERIENCE'} />
        <div className="header-part">
          <div id="yinYong" className={`button ${state ? "button-click" : ""}`}
            onClick={() => this.changeState(1)}>
            应用扫码体验
            <div className={`down ${state ? "down-color" : ""}`}>&nbsp;</div>
          </div>
        </div>
        
        <div id="content">
          <div className="content-picture-top">
            {
              appCodePicList.map((item, index) => {
                return (
                  <div key={index} className="QR-div">
                    <img src={item.codeUrl} alt="" className="images" />
                    <div className="image-name">{item.appName}</div>
                    <p className="image-content">{item.content}</p>
                    <p className="image-content">{item.content2}</p>
                    <div className="phone">
                      {/* <img
                        alt='#'
                        src="https://file.5rs.me/oss/uploadfe/png/15f3fbc3be155046f89a0b5ab50e4014.png?x-oss-process=image/auto-orient,0/resize,p_100"
                        className="phone-title" /> */}
                      <img
                        alt='#'
                        src="https://file.5rs.me/oss/uploadfe/png/55ed18a47e3739dc4930597a4bf6b8f2.png?x-oss-process=image/resize,p_100"
                        className="phone-border" />
                      <div className="image-back">
                        <img
                          alt='#'
                          src={add}
                          className={`phone-image ${appExamplePicList.length<=1?'noAnimation':''}`} />
                      </div>
                    </div>
                  </div>
                );
              })
            }

          </div>
        </div>

        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    );
  }
}

export default Product;

const OPTIONS = {
  'ITEM_BANK': '在线题库',
  'BOOKCARD': '读书笔记',
  'EBOOK': '电子书',
  'VIDEO': '视频学习',
  'AUDIO': '音频学习',
  'QA': '专家咨询',
  'BM': '活动报名',
}