
import React from "react"

import jinbaihuiApi from "@/api/jinbaihuiApi"

import Weather from '../newHome/components/wrather'
import Operation from "../RealTime/Operation/Operation"
import Table from "./components/Table/Table"

import './index.scss'
import Utils from "@/utils/utils"
import ImgList from "./components/ImgList/ImgList"

class Page extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false,
      gaoKaoData: {},
      mapImg: ''
    }
  }

  getBounding() {
    if (this.chart) {
      const clientRect = this.chart.getBoundingClientRect();
      this.setState({
        chartBounding: Object.assign(clientRect, {height: clientRect.height * .9 || 0})
      })
    }
  }

  getGaoKaoData = (regionId) => {
    jinbaihuiApi.displayGaokao({ regionId: regionId }).then(data => {

      const { gaokaoService, subjectAnalysisList, qualityAnalysisList, studentRankingList, gaokaoResourceList } = data;

      let { aList: activityImgs, sList: studentImgs, mapImg } = gaokaoResourceList.reduce((acc, cur) => {
        if(cur.type === '活动图片') {
          acc.aList.push(cur);
        } else if(cur.type === '考生展示') {
          acc.sList.push(cur);
        } else if(cur.type === '地图') {
          acc.mapImg = cur.img;
        }
        return acc;
      }, { aList: [], sList: [], mapImg: '' });

      const rankingList = studentRankingList.map((item, index) => ({
        ...item,
        sequence: index + 1
      }));
      
      this.setState({
        gaokaoService,
        subjectAnalysisList,
        qualityAnalysisList,
        studentRankingList: rankingList,
        activityImgs,
        studentImgs,
        mapImg,
      });

    });
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://oss.5rs.me/oss/uploadfe/png/0b05793956f1e315d1ba8641c6a18972.png)`
  }

  componentWillMount() {
    Promise.all([
      jinbaihuiApi.gaokaoRegionList(),
      jinbaihuiApi.getPollingTime(),
    ]).then(([data, data2]) => {
      const regionList = data || []
      const { pollingTime = 10 } = data2 || {};
      this.setState({
        regionList
      });
      if(regionList.length) {
        let regionId = data[0].regionId;
        this.getGaoKaoData(regionId);
        let index = 0
        this.getDataTimer = setInterval(() => {
          index = (index + 1) % regionList.length;
          regionId = data[index].regionId;
          this.getGaoKaoData(regionId);
        }, pollingTime * 1000);
      }
    })
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://file.5rs.me/oss/uploadfe/png/f5ffd88849e964acf7367db90f431f85.png)`
    this.getDataTimer && clearInterval(this.getDataTimer);
  }

  render() {
    const {
      isShow,
      gaokaoService = {}, subjectAnalysisList, qualityAnalysisList, studentRankingList,
      activityImgs, studentImgs, mapImg
    } = this.state

    return <React.Fragment>
      <div className="XinGaoKao">
        <Weather/>
        <div className="head">
        </div>
        <div className="page-title">
          {/* 湖北七市（州）教科研协作体2022念高三3月联考（物理方向） */}
          {gaokaoService.regionName}
        </div>
        <div className="body">
          {/* left */}
          <div className="left-container">
            {/* 学科分析 */}
            <div className="subject-analysis">
              <Table
                className="table-subject"
                titleList={TITLE_LIST}
                itemList={subjectAnalysisList}
                lines={5}
              />
            </div>
            {/* 活动 */}
            <div className="activity-wrap">
              <ImgList
                imageList={activityImgs}
                className="activity-item"
                length={4}
              />
            </div>
            {/* 优秀学生展示 */}
            <div className="excellent-students">
              <ImgList
                imageList={studentImgs}
                className="student-item"
                length={2}
              />
            </div>
          </div>

          {/* center */}
          <div className="center-container">
            <div className="count-list-wrap">
              {
                COUNT_LIST.map(item => {
                  return (
                    <div className={`count-item item_${item.key}`} key={item.key}>
                      <span className="item-value">{gaokaoService[item.vKey]}</span>
                      <span className="item-unit">{item.unit}</span>
                    </div>
                  )
                })
              }
            </div>
            <div className="map-img" style={{ backgroundImage: `url(${mapImg})`}} />
          </div>

          {/* right */}
          <div className="right-container">
            <div className="quality-wrap">
              <Table
                className="table-quality"
                titleList={QUALITY_TITLE_LIST}
                itemList={qualityAnalysisList}
                lines={5}
              />
            </div>
            <div className="top-list">
              <Table
                className="table-top-list"
                titleList={RANK_TITLE_LIST}
                itemList={studentRankingList}
                lines={12}
                // interval={3}
              />
            </div>

          </div>
        </div>
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    </React.Fragment>
  }
}

const TITLE_LIST = [
  { key: 'subjectName', title: '学科' },
  { key: 'examNum', title: '参考人数' },
  { key: 'averageScore', title: '均分' },
  { key: 'maxScore', title: '最高分' }
]
const COUNT_LIST = [
  { key: 'school', vKey: 'examSchoolNum', unit: '所' }, //
  { key: 'personCount', vKey: 'finalExamPeople', unit: '人' },
  { key: 'lowestScore', vKey: 'minimumScore', unit: '分' },
  { key: 'highestScore', vKey: 'highScore', unit: '分' },
  { key: 'highCount', vKey: 'highLine', unit: '人' },
  { key: 'firstTier', vKey: 'firstLine', unit: '人' },
  { key: 'secondTier', vKey: 'secondLine', unit: '人' },
]
const QUALITY_TITLE_LIST = [
  { key: 'subjectName', title: '学科' },
  { key: 'evalution', title: '试卷评价' },
  { key: 'diffculty', title: '难度' },
  { key: 'differentiation', title: '区分度' }
]
const RANK_TITLE_LIST = [
  { key: 'rankNumber', title: '排名', renderFn: RenderSequence },
  { key: 'stuName', title: '姓名' },
  { key: 'schoolName', title: '学校' },
  { key: 'score', title: '总分' }
]

function RenderSequence(props) {
  const { sequence } = props;
  return (
    <div className="sequence-wrap">
      <span className={`sequence-icon icon_${sequence}`} />
      <span className="sequence-content">{sequence}</span>
    </div>
  )
}

export default Page;
