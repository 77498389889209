/**
 *  Date    : 2018/9/18
 *  Author  : yangpeng
 *  Declare : Map
 *
 */

import React from 'react';

import api from '@/api/tv';
import getData from '@/utils/getData';
import users from '@/assets/images/usershead.jpg';

import './Maps.scss';

class Maps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgList: [],
    };
    this.pictureSet = [];
  }

  /**
   * 从总数据中单独提取34位数据
   */
  getInfo = (type, message = []) => {
    this.pictureSet = this.pictureSet.concat(message.filter(v=>!!v.customerId));
    if (this.pictureSet.length > 20) {
      this.pictureSet = this.pictureSet.slice(7,50)
    }
  };

  deleteSameObj=(obj)=>{
    let arr = []
    obj.map((item,index)=>{
      let arr1 = obj.filter((v)=>{
        return item.nickName === v.nickName
      })
      if(arr1.length===1){
        arr.push(item)
      }
    })
    return arr 
  }

  /**
   * 每间隔4S更新一次图片轮播的数据
   */
  getImage(){
    let times,newImages=[];
    const images = ()=>{
      if(this.pictureSet.length>12){
        clearTimeout(times);
        newImages=this.pictureSet.slice(0,12);
        this.setState({
          imgList:this.deleteSameObj(newImages)
        })
      }
      times = setTimeout(images,4000)
    };
    images();
  }

  // /**
  //  * 延迟20s提取一次数据
  //  */
  // getImg() {
  //   let time;
  //   const getImages = () => {
  //     clearTimeout(time);
  //     api.getRecentUser({type: 1, count: 10})
  //       .then((data) => {
  //         this.getInfo(data);
  //         time = setTimeout(getImages, 20000)
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   };
  //   getImages()
  // }

  componentDidMount() {
    getData.addDataListener(this.getInfo);
    this.getImage();
  }

  render() {
    const {imgList}= this.state;
    return (
      <div className='Maps'>
        <div className='img-box'>
          <ul className='img-ul'>
            {imgList.map((item, i)=>{
              return(
                <li key={`${item.customerId}-${Math.random()}-${i}`}
                    className={`img-li ${RUN[Math.floor(Math.random()*6)]}`}>
                  <img src={item.headUrl || users}/>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='map-box'>
          {dots.map((items, index) => {
            return (
              <Coordinate data={items} key={index}/>
            )
          })}
        </div>
        <Popup newPeople={this.props.newPeople}/>
      </div>
    );
  }
}

/**
 * 地图上的动态效果组件
 */
class Coordinate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {data: {x, y, z}} = this.props;
    return (
      <div className='dot' style={{right: `${x}vw`, bottom: `${y}vw`}}>
        <div className="container">
          <span className='circle first-map' style={{height: `${z * 1.1}vw`, width: `${z * 1.1}vw`}}/>
          <span className='circle second-map' style={{height: `${z * 1.65}vw`, width: `${z * 1.65}vw`}}/>
          <span className='circle three-map' style={{height: `${z * 2.2}vw`, width: `${z * 2.2}vw`}}/>
        </div>
      </div>
    )
  }
}

/**
 * 地图上的弹出框效果组件
 */
class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {newPeople} = this.props;
    if (!newPeople.headUrl) {
      return null
    }
    return (
      <div className='popup-box' key={newPeople.customerId}>
        <img src={newPeople.headUrl} className='popup-img'/>
        <p>{newPeople.nickName}</p>
        <div className='popup-information'>
          <div className='popup-city'>
            <span>{newPeople.province}</span>
            {newPeople.province && newPeople.city && <span>·</span>}
            <span>{newPeople.city}</span>
          </div>
          <span className={`user-span ${newPeople.sex === 1 ? 'man' : 'woman'}`}>
            <i className={`ic ic-${newPeople.sex === 1 ? 'male' : 'female'}`}/>
          </span>
        </div>
      </div>
    )
  }
}

const dots = [
  {
    x: 6.5,
    y: 9,
    z: 0.9
  },
  {
    x: 10.5,
    y: 6.5,
    z: 1
  },
  {
    x: 16,
    y: 8,
    z: 0.9
  },
  {
    x: 19,
    y: 12.5,
    z: 0.8
  },
  {
    x: 8.5,
    y: 13.5,
    z: 0.8
  }
];

export default Maps;

const RUN =[
  'style1',
  'style2',
  'style3',
  'style4',
  'style5',
  'style6',
];


