import React from "react"

import jinbaihuiApi from "@/api/jinbaihuiApi"

import Weather from '../newHome/components/wrather'
import Operation from "../RealTime/Operation/Operation"

import './index.scss'
import WordLineList from "./components/WordLineList/WordLineList"
import ProcessList from "./components/ProcessList/ProcessList"
import SampleCaseList from "./components/SampleCaseList/SampleCaseList"

class LibraryService extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false,
      isLoading: true
    }
  }

  calculateCount = (num) => {
    if(num >= 10000) {
      return Math.round(num / 1000) / 10 + '万';
    }
    return num;
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://oss.5rs.me/oss/uploadfe/png/0b05793956f1e315d1ba8641c6a18972.png)`
  }

  componentWillMount() {
    jinbaihuiApi.displayLibraryService().then(data => {
      const { schoolServiceList, bookRankingList, libraryResourceList } = data;
      const processLists = libraryResourceList.reduce((acc, cur) => {
        if(!acc[cur.type]) acc[cur.type] = [];
        acc[cur.type].push(cur);
        return acc;
      }, {});
      const { img: systemImg} = (processLists['系统建设'] && processLists['系统建设'][0]) || {};
      const { img: activityVideo} = (processLists['活动视频'] && processLists['活动视频'][0]) || {};
      const sampleCases = processLists['样板案例'] || [];
      this.setState({
        isLoading: false,
        schoolServiceList: schoolServiceList.map((v, i) => ({ ...v, sequence: i + 1})),
        bookRankingList: bookRankingList.map((v, i) => ({ ...v, sequence: i + 1})),
        systemImg, activityVideo, sampleCases, processLists
      })
    })
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://file.5rs.me/oss/uploadfe/png/f5ffd88849e964acf7367db90f431f85.png)`
  }

  render() {
    const {
      isShow, isLoading,
      schoolServiceList, bookRankingList,
      systemImg, activityVideo, sampleCases, processLists
    } = this.state
    if(isLoading) return null;
    return (
      <div className="LibraryService_tkyEkcEE">
        <Weather />
        <div className="head">
        </div>

        <div className="body">
          <div className="top-container">
            {/* 系统集成 */}
            <div className="system-wrap">
              <div className="system-img" style={{ backgroundImage: `url(${systemImg})`}} />
            </div>
            {/* 服务学校 */}
            <div className="serve-school-wrap">
              <WordLineList
                list={schoolServiceList}
                length={7}
                getWordFn={v => `${v.serviceSchool} - ${v.serviceTime}`}
              />
            </div>
            {/* 图书排行 */}
            <div className="popular-book-wrap">
              <WordLineList
                list={bookRankingList}
                length={7}
                getWordFn={v => `${v.bookName} - ${v.bookAccount} - ${v.bookPress} - ${v.bookType}`}
              />
            </div>
            {/* 活动宣传 */}
            <div className="activity-wrap">
              <video
                className="video-item"
                controls
                autoPlay
                loop
                src={activityVideo}
              />
              <div className="item-title">宣传片</div>
            </div>
          </div>

          <div className="bottom-container">
            <div className="bottom-left-wrap">
              <ProcessList lists={processLists} />
            </div>
            <div className="sample-case-wrap">
              <SampleCaseList list={sampleCases} length={6} />
            </div>
          </div>

        </div>
        
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    );
  }
}

export default LibraryService;
