/**
 *  Date    : 2018/9/4
 *  Author  : yp
 *  Declare : createTime
 *
 */

import React from "react";

import utils from '../../utils/utils';

import "./Data.scss";

class Data extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      date: {}
    };
  }

  componentWillMount() {
    this.setState({
      date: utils.getDate()
    });
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        date: utils.getDate()
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {Y, M, W, D, H, S, min} = this.state.date;
    return (
      <div className="Date">
        {`${Y}年${M}月${D}日 ${H}:${min}:${S} 星期${WEEKS[W]}`}
      </div>
    );
  }
}

export default Data;

const WEEKS = ["日", "一", "二", "三", "四", "五", "六",];
