"use strict"

import React from "react"

import './index.scss'


class Page extends React.PureComponent {
    constructor (props) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
    }
    componentWillUnmount () {
    }
    render () {
        return <>
            <div className="rightBlock">
                <p className="rightBlock_title">
                    {this.props.title}
                </p>
                <div>
                    {this.props.children}
                </div>
            </div>
        </>
    }
}
export default Page;