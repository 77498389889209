/**
 *  Date    : 2018/9/7
 *  Author  : ZhangJin
 *  Declare : Data
 *  Desc    : 数据总览界面
 */

import React from "react";

import Utils from "../../utils/utils";
import Header from "../component/Header";
import Echarts from "../../common/ECharts/ECharts";
import Operation from "../RealTime/Operation/Operation"
import getData from '@/utils/getData';

import api from "../../api/tv";

import "./Data.scss";

const chartData = {
  grid: {
    top: 20,
    bottom: 60
  }, //x轴与与轴区域设置
  title: {
    text: "近三月流量PV趋势",
    bottom: 0,
    left: 145,
    textStyle: {
      color: "#78b3ff",
      fontSize: 22,
      fontWeight: 500
    }
  }, //图表标题设置
  color:['#eef9ff','#57b0f3','#6545dc'],
  legend: {
    bottom: 0,
    right: 160,
    itemWidth: 22, //月份前图标大小
    itemHeight: 22,
    itemGap: 50,
    textStyle: {
      color: "white",
      fontFamily: "PingFang-SC-Medium Microsoft YaHei UI",
      fontSize: 20
    },
    inactiveColor:'#666',
    data: [
      {
        name: "",
        
      },
      {
        name: "",
        
      },
      {
        name: "",
        
      }
    ] // data对应series中每条线的名称及图标
  }, //每条数据线对应的图标及样式设置
  xAxis: {
    type: "category",
    boundaryGap: false, //设置坐标轴两端不留白，否则数据线水平长度与轴不一致
    axisTick: {
      show: false
    },
    axisLabel: {
      padding: [15, 0, 0, 0],
      align: "left"
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#464754"
      }
    }
  }, //x轴
  yAxis: {
    type: "value",
    axisLabel: {
      padding: [0, 8, 6, 0]
    },
    axisLine: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: "#464754"
      }
    },
  }, //y轴
  textStyle: {
    fontSize: 26,
    fontFamily: "GROTESKIA",
    color: "#42445c"
  }, //全局文字样式
  series: [
    {
      name: "",
      type: "line",
      smooth: true,
      symbol: "none",
      lineStyle: {
        color: "#eef9ff"
      },
      data: null,
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#eef9ff" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "transparent" // 100% 处的颜色
            }
          ]
        }
      }
    },
    {
      name: "",
      type: "line",
      smooth: true,
      symbol: "none",
      lineStyle: {
        color: "#57b0f3"
      },
      data: null,
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#78b3ff" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "transparent" // 100% 处的颜色
            }
          ]
        }
      }
    },
    {
      name: "",
      type: "line",
      smooth: true,
      symbol: "none",
      lineStyle: {
        color: "#6545dc"
      },
      data: null,
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#78b3ff" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "transparent" // 100% 处的颜色
            }
          ]
        }
      }
    }
  ] //根据数据生成对应的折线并设置对应样式
};

class Data extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalMoney: 0,
      bookCount: 0,
      onlineUserCount: 0,
      isShow: false,
      online: 0, // 线上
      offline: 0, // 线下
      DayMoney90: 0 // 90天收益
    };
    this.screenWidth = 0;
    this.dateOne = "";
    this.dateTwo = "";
    this.dateThree = "";
  }

  //获得页面数据
  getTotalData() {
    let timerOne,
      timerTwo,
      timeoutOne = 1000 * 30,
      timeoutTwo = 1000 * 3600 * 24; //每隔1s更新一次数据
    const getOnlineUser = () => {
      clearTimeout(timerOne);
      //获得实时在线人数
      this.updateOnLineUser();
      timerOne = setTimeout(getOnlineUser, timeoutOne);
    };

    const getOtherData = () => {
      clearTimeout(timerTwo);
      //获得90天交易额及书籍数量
      api.getTotalTradeCount().then(data => {
        this.setState({
          totalMoney: data.message ? data.data : data
        });
      });
      // api.getBookCount().then(data => {
      //   this.setState({
      //     bookCount: data.message ? data.data : data
      //   });
      // });
      //获得近三个月流量数据并传到chartData
      api.get3MonthPv().then(data => {

        chartData.series[2].data = this.getMonthListNum(
          data.lastOneMonth
        );
        chartData.legend.data[2].name = data.lastOneMonth[0]?.day?.slice(0,-3)
        chartData.series[2].name = data.lastOneMonth[0]?.day?.slice(0,-3)

        chartData.series[1].data = this.getMonthListNum(
          data.lastTwoMonth
        );
        chartData.legend.data[1].name = data.lastTwoMonth[0]?.day?.slice(0,-3)
        chartData.series[1].name = data.lastTwoMonth[0]?.day?.slice(0,-3)

        chartData.series[0].data = this.getMonthListNum(
          data.lastThreeMonth
        );
        chartData.legend.data[0].name = data.lastThreeMonth[0]?.day?.slice(0,-3)
        chartData.series[0].name = data.lastThreeMonth[0]?.day?.slice(0,-3)
        this.forceUpdate();
      });
      timerTwo = setTimeout(getOtherData, timeoutTwo);
    };

    getOnlineUser();
    getOtherData();
  }

  getMonthListNum(data) {
    if (data && data.length) {
      return data.map(item => {
        return item.count;
      });
    }

  }

  //获取前三个月的月份并传入chartData
  getLastThreeMonth() {
    let date = new Date(),
      thisYear,
      lastYear,
      thisMonth,
      lastMonth,
      lastTwoMonth,
      lastThreeMonth;
    thisYear = date.getFullYear();
    lastYear = thisYear - 1;
    //月份为0-11，故+1为当前月份[1-12]
    thisMonth = date.getMonth() + 1;
    //
    lastThreeMonth = thisMonth - 3;
    switch (lastThreeMonth) {
      case -2: {
        lastThreeMonth = 10;
        lastTwoMonth = 11;
        lastMonth = 12;
        this.dateOne = lastYear + "-" + lastMonth;
        this.dateTwo = lastYear + "-" + lastTwoMonth;
        this.dateThree = lastYear + "-" + lastThreeMonth;
      }
        break;
      case -1: {
        lastThreeMonth = 11;
        lastTwoMonth = 12;
        lastMonth = 1;
        this.dateOne = thisYear + "-0" + lastMonth;
        this.dateTwo = lastYear + "-" + lastTwoMonth;
        this.dateThree = lastYear + "-" + lastThreeMonth;
      }
        break;
      case 0: {
        lastThreeMonth = 12;
        this.dateOne = thisYear + "-0" + lastMonth;
        this.dateTwo = thisYear + "-0" + lastTwoMonth;
        this.dateThree = lastYear + "-" + lastThreeMonth;
      }
        break;
      default: {
        lastTwoMonth = thisMonth - 2;
        lastMonth = thisMonth - 1;
        this.dateOne =
          thisYear + "-" + (lastMonth < 10 ? "0" + lastMonth : lastMonth);
        this.dateTwo =
          thisYear +
          "-" +
          (lastTwoMonth < 10 ? "0" + lastTwoMonth : lastTwoMonth);
        this.dateThree =
          thisYear +
          "-" +
          (lastThreeMonth < 10 ? "0" + lastThreeMonth : lastThreeMonth);
      }
    }
    chartData.legend.data[2].name = this.dateOne;
    chartData.series[2].name = this.dateOne;
    chartData.legend.data[1].name = this.dateTwo;
    chartData.series[1].name = this.dateTwo;
    chartData.legend.data[0].name = this.dateThree;
    chartData.series[0].name = this.dateThree;
  }

  //获取屏幕宽度对chart图表尺寸进行调整
  resizeChart() {
    this.screenWidth = window.innerWidth;
    chartData.grid.bottom = (this.screenWidth * 60) / 2048;
    chartData.grid.top = (this.screenWidth * 20) / 2048;
    chartData.title.left = (this.screenWidth * 115) / 2048;
    chartData.legend.right = (this.screenWidth * 120) / 2048;
    chartData.legend.itemWidth = (this.screenWidth * 22) / 2048;
    chartData.legend.itemHeight = (this.screenWidth * 22) / 2048;
    chartData.legend.itemGap = (this.screenWidth * 50) / 2048;

    chartData.legend.textStyle.fontSize = (this.screenWidth * 16) / 2048;
    chartData.xAxis.axisLabel.fontSize = (this.screenWidth * 60) / 2048;
    chartData.yAxis.axisLabel.fontSize = (this.screenWidth * 26) / 2048;
    chartData.title.textStyle.fontSize = (this.screenWidth * 22) / 2048;
    chartData.xAxis.axisLabel.padding[0] = (this.screenWidth * 15) / 2048;
  }

  updateOnLineUser = () => {
    api.getOnlineCount().then(data => {
      this.setState({
        // 实时在线人数
        onlineUserCount: (data.message ? data.data : data) + 76
      });

    });
  };
  // 累计收益
  getSumDivideAmount = new Promise((res, rej) => {
    api.getSumDivideAmount().then(data => {
      // 增加数值 线上 105923 - 5581 = 100342
      //         线下 752932 - 0 = 752932
      //        总 100342 + 752932 = 853274
      data.onlineIncome += 100342;
      data.offlineIncome += 752932;
      res(data)
    });
  })

  // 90天收益 getLast90DayMoney4Times
  getLast90DayMoney4Times = new Promise((res, rej) => {
    api.getTotalTradeCount().then(data => {
      // 3928 - 110.5 = 3817.5
      data += 3817.5;
      res(data)
    });
  })
  fetchData = () => {
    Promise.all([this.getSumDivideAmount, this.getLast90DayMoney4Times]).then((data) => {
      this.setState({
        online: data[0].onlineIncome ? data[0].onlineIncome : 0,
        offline: data[0].offlineIncome ? data[0].offlineIncome : 0,
        DayMoney90: data[1] ? data[1] : 0
      });
    })
  }
   // 金额分隔
  formatMoney = (nStr)=> {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  componentWillMount() {
    this.getTotalData();
    // this.getLastThreeMonth();
    this.resizeChart();
    this.fetchData()
  }

  componentDidMount() {
    this.timerUserCount = window.setInterval(this.updateOnLineUser, 2000);
    getData.stop()
  }

  componentWillUnmount() {
    window.clearInterval(this.timerUserCount);
  }

  render() {
    const { totalMoney, bookCount, onlineUserCount, isShow, online, offline, DayMoney90 } = this.state;
    return (
      <div className="data">
        <Header title={"收益总览"} text={"DATA SCREENING"} />
        <div className="data-content">
          <div className="header">
            <div className="header-item">
              <p>{this.formatMoney(DayMoney90)}</p>
              <span>90天交易额（元）</span>
            </div>
            <div className="header-item">
              <p>{this.formatMoney(online)}</p>
              <span>线上总收益（元）</span>
            </div>
            <div className="header-item">
              <p>{this.formatMoney(offline)}</p>
              <span>线下总收益(元)</span>
            </div>
          </div>
          {/* <div className="header">
            <div className="header-left">
              90天交易总额&nbsp;&nbsp;TOTAL TRANSACTION
            </div>
          </div> */}
          {/* <div className="total-money">
            <span className="font-groteskia money">
              {Number(totalMoney) !== 0 ? Utils.getString(totalMoney) : 0}
            </span>
          </div> */}
          <div className="footer clearfix">
            <div className="footer-left left">
              {chartData.series[0].data && (
                <Echarts data={chartData} className="echart" />
              )}
            </div>
            <div className="footer-right right">
              {/* <div className="box-one">
                <div className="box">
                  <p className="data-name">书籍总数（品种数）</p>
                  <span className="num font-groteskia">
                    {Number(bookCount) !== 0 ? Utils.getString(bookCount) : 0}
                  </span>
                </div>
              </div> */}
              <div className="box-two">
                <div className="box">
                  <p className="data-name">实时在线人数（人）</p>
                  <span className="num font-groteskia">
                    {Number(onlineUserCount) !== 0 ? Utils.getString(onlineUserCount) : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    );
  }
}

export default Data;

