

import React from "react"
import * as Session from '@/utils/session';
import Utils from '@/utils/utils'
import api from "@/api/tv";
import './index.scss'

class Page extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentTime: Utils.getDate(),
            weatherObj: {},
            fullScreen: true
        }
    }

    componentDidMount() {
        const isFullScreen = !!Session.get(FULL_SCEEN);
        this.setState({
            fullScreen: !isFullScreen
        });
        this.getWeather()
        // this.listenerFullScreen()
        setInterval(() => {
            this.setState({
                currentTime: Utils.getDate()
            })
        }, 1000)
    }
    
    launchFullscreen = (element) => {
        this.setState({
            fullScreen: false
        })
        Session.put(FULL_SCEEN, 'FULL_SCEEN');
        if (element.requestFullscreen) {
            element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen()
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullScreen()
        }
    }
    // 监听页面变化
    // listenerFullScreen = () => {
    //   document.addEventListener('webkitfullscreenchange', () =>{ 
    //     console.log(111111111111111)
    //     if(this.state.fullScreen){
    //       this.setState({
    //         fullScreen: false
    //       })
    //     }else{
    //       this.setState({
    //         fullScreen: true
    //       }) 
    //     }
    //   });
    // }
    exitFullscreen = () => {
        this.setState({
            fullScreen: true
        })
        Session.remove(FULL_SCEEN);
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
    // 获取天气
    getWeather = () => {
        api.getWeather().then((data) => {
            if (data.status === 200) {
                this.setState({
                    weatherObj: data.data.forecast[0]
                })
            }
        })

    }

    numToChinese = (val) => {
        let chinese = {
            1: '一',
            2: '二',
            3: '三',
            4: '四',
            5: '五',
            6: '六',
            0: '日',
        }
        return chinese[val]
    }
    render() {
        const { weatherObj, fullScreen } = this.state
        const { launchFullscreen, exitFullscreen } = this
        return <>
            <div className="weather">
                <span className="gradient time-content">{`${this.state.currentTime.H}:${this.state.currentTime.min}:${this.state.currentTime.S}`}</span>
                <span className="divider gradient"> | </span>
                <span className="gradient date-content">{`${this.state.currentTime.Y}-${this.state.currentTime.M}-${this.state.currentTime.D}`}</span>
                <span className="mrg gradient week-content">{`星期${this.numToChinese(this.state.currentTime.W)}`}</span>
                {weatherObj.type ? <span className="gradient weather-content">{weatherObj.type}</span> : ''}
                {weatherObj.low ? <span className="mrg gradient air-temperature">{`${weatherObj.low?.split(' ')[1]} - ${weatherObj.high?.split(' ')[1]}`}</span> : ''}
                {fullScreen ? <div className="btn" onClick={() => { launchFullscreen(document.documentElement) }}>全屏</div> :
                    <div className="btn" onClick={() => { exitFullscreen(document.documentElement) }}>退出</div>}
            </div>
        </>
    }
}

const FULL_SCEEN = 'FULL_SCEEN';

export default Page;