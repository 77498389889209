/*
 * @Author: paco
 * @Date: 2018-04-12 10:06:53
 * @Last Modified by: paco
 * @Last Modified time: 2018-04-12 10:14:55
 */


import createAxios from 'axios';
import { HEAD_URL_LIST } from '@/constants/headUrls';

import axiosService from './axios';

const axios = createAxios.create();

const TimesCenter = axiosService('times');
const JinBaiHui = axiosService('jinbaihui');

export default {
  getRecentUser: function (params) {
    return TimesCenter('get', 'project', 'getRecentData', { params }).then(res => {
      res = Array.isArray(res) ? res : [];
      res = (res || []).map(item => {
        if(!item.nickName) {
          item.nickName = `读者*${String(item.customerId).slice(-5)}`;
          const len = HEAD_URL_LIST.length;
          item.headUrl = HEAD_URL_LIST[item.customerId % len];
        }
        return item;
      });
      return res;
    })
  },
  getTotalData: function (params) {
    return TimesCenter('get', 'project', 'getTotalData')
  },
  /*  获取实时在线人数 */
  getOnlineCount: function () {
    return TimesCenter('get', 'project', 'getOnlineCount')
  },
  /* 90天交易额 */
  getTotalTradeCount: function () {
    return TimesCenter('get', 'project', 'getLast90DayMoney4Times')
  },
  /* 图书总量 */
  getBookCount: function () {
    return TimesCenter('get', 'project', 'getBookCount')
  },
  /* 数据总览近3月流量pv趋势 */
  get3MonthPv: function () {
    return TimesCenter('get', 'project', 'get3MonthPv')
  },
  getSlideSetting: () => {
    return TimesCenter('get', 'setting', 'getSlideSetting')
  },
  getTimesAgents: () => {
    return TimesCenter('get', 'project', 'getTimesAgents')
  },
  updateSlideSetting: (data) => {
    return TimesCenter('post', 'setting', 'updateSlideSetting', data)
  },
  getScreenShow: (data) => {
    return TimesCenter('get', 'setting', 'getScreenShow', data)
  },
  getData: (data) => {
    return axios(data)
  },
  getAddFriendsCount: function () {
    return TimesCenter('get', 'project', 'getAddFriendsCount')
  },
  getMsgCount: function () {
    return TimesCenter('get', 'project', 'get7Day1V1MsgCount')
  },
  //90天小程序用户量统计
  getActiveGroupCount: function () {
    return TimesCenter('get', 'project', 'getRecent90DayAppletUserCount4Times')
  },
  getJoinGroupUserCount: function () {
    return TimesCenter('get', 'project', 'getJoinGroupUserCount')
  },
  getActiveCountTrend: function () {
    return TimesCenter('get', 'project', 'get15DayActiveCountTrend')
  },
  //获取产品体验下的社群书产品体验
  getBookCodeInfo: data => {
    return TimesCenter('get', 'setting', 'getBookCodeInfo', data);
  },
  //获取产品体验下的应用扫码体验
  getAppCodeInfo: data => {
    return JinBaiHui('get', 'bigScreen', 'listExperienceCode', data);
  },
  //获取用户总览下的数据
  getAgentStatisInfo: data => {
    return TimesCenter('get', 'project', 'getAgentStatisInfo', data);
  },
  // 读者行为时段分析
  getBehaviorAnalysis: data => {
    return TimesCenter('get', 'project', 'timeGapCollect', data);
  },
  // 运营数，作者数，编辑数
  getCountByAgent: (data) => {
    return TimesCenter('get', 'project', 'getCountByAgent', data);
  },
  // 累计收益
  getSumDivideAmount: (data) => {
    return TimesCenter('get', 'project', 'getSumDivideAmount', data);
  },
  // 书刊品种数量
  getBookCount: (data) => {
    return TimesCenter('get', 'project', 'getBookCount', data);
  },
  // 二维码数
  getQrCount4Agent: (data) => {
    return TimesCenter('get', 'project', 'getQrCount4Agent', data);
  },
  // 应用数量
  count4Agent: (data) => {
    return TimesCenter('get', 'project', 'count4Agent', data);
  },
  // 作品数量
  overview4Agent: (data) => {
    return TimesCenter('get', 'project', 'overview4Agent', data);
  },
  // 读者性别统计
  getUserSexOrder: (data) => {
    return TimesCenter('get', 'project', 'getUserSexOrder', data);
  },
  // 累计读者数
  getReaderCount: (data) => {
    return TimesCenter('get', 'project', 'getReaderCount', data);
  },
  // 实时在线人数
  getOnlineCount: (data) => {
    return TimesCenter('get', 'project', 'getOnlineCount', data);
  },
  // 小睿用户数
  getRecent90DayAppletUserCount4Times: (data) => {
    return TimesCenter('get', 'project', 'getRecent90DayAppletUserCount4Times', data);
  },
  // 读者最多的省份
  getProvince4UserMost: (data) => {
    return TimesCenter('get', 'project', 'getProvince4UserMost', data);
  },
  // 获取天气
  getWeather: (data) => {
    return TimesCenter('get', 'setting', 'getWeather', data);
  }
}
