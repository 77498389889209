import React from 'react'

import './TitleItem.scss';

export default function TitleItem(props) {

  const { title } = props;
  
  return (
    <div className='TitleItem_VsDDOj99'>
      <span className='title-content'>{title}</span>
    </div>
  )
}
