import React from "react"

import jinbaihuiApi from "@/api/jinbaihuiApi"

import Weather from '../newHome/components/wrather'
import Operation from "../RealTime/Operation/Operation"
import ResourceChart from "./components/ResourceChart/ResourceChart"
import BookPublishChart from "./components/BookPublishChart/BookPublishChart"
import VisitChart from "./components/VisitChart/VisitChart"

import './index.scss'
import Applications from "./components/Applications/Applications"
import Table from "../XinGaoKao/components/Table/Table"

class BigClassroom extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false,
      isLoading: true
    }
  }

  calculateCount = (num) => {
    if(num >= 10000) {
      return Math.round(num / 1000) / 10 + '万';
    }
    return num;
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://oss.5rs.me/oss/uploadfe/png/0b05793956f1e315d1ba8641c6a18972.png)`
  }

  componentWillMount() {
    jinbaihuiApi.displayCourse().then(data => {
      const { industryProportionList, publicationList, courseVisitsHours, courseVisitsMonths, course, resourceRankingList } = data;
      this.setState({
        isLoading: false,
        industryProportionList,
        publicationList,
        courseVisitsHours,
        courseVisitsMonths,
        course: course || {},
        resourceRankingList
      })
    })
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundImage = `url(https://file.5rs.me/oss/uploadfe/png/f5ffd88849e964acf7367db90f431f85.png)`
  }

  render() {
    const {
      isShow, isLoading,
      industryProportionList, publicationList, courseVisitsHours, courseVisitsMonths,
      course, resourceRankingList
    } = this.state
    if(isLoading) return null;
    return (
      <div className="BigClassroom_tkyEkcEE">
        <Weather />
        <div className="head">
        </div>

        <div className="body">
          <div className="left-container">
            <div className="resource-chart-wrap">
              <ResourceChart list={industryProportionList} />
            </div>
            <div className="book-publish-wrap">
              <BookPublishChart list={publicationList} />
            </div>
          </div>
          
          <div className="center-container">
            <div className="visit-data-wrap">
              <VisitChart
                course={course}
                courseVisitsHours={courseVisitsHours}
                courseVisitsMonths={courseVisitsMonths}
              />
            </div>
            <div className="application-data-wrap">
              <Applications course={course} />
            </div>
          </div>

          <div className="right-container resource-ranking-wrap">
            <Table
              className="table-ranking-list"
              titleList={RANK_TITLE_LIST}
              itemList={resourceRankingList}
              lines={19}
            />
          </div>
        </div>
        
        <div className="change-screen">
          <Operation {...this.props} callback={() => this.setState({
            isShow: !isShow
          })}></Operation>
        </div>
      </div>
    );
  }
}

const RANK_TITLE_LIST = [
  { key: 'keyWord', title: '关键词' },
  { key: 'circulation', title: '发行量' },
  { key: 'registration', title: '注册量' },
  { key: 'hotValue', title: '热度', renderFn: HotIcon }
]

function HotIcon(props) {
  const { hotValue } = props;
  const len = hotValue <= 5 ? hotValue : 5;
  return (
    [1, 2, 3, 4, 5].map((key, index) => {
      const active = index < len;
      return <span className={`hot-icon ${active ? 'active' : ''}`} key={key} />
    })
  )
}

export default BigClassroom;
