/*
 *  Project : UIComponent
 *  Date    : 2016/9/26
 *  Author  : Zhang Guimei
 *  Declare : ECharts.js
 */


import React, {PropTypes} from 'react';
import {fromJS, is} from 'immutable';

import echarts from 'echarts';

class ECharts extends React.Component {
  static defaultProps = {
    data: {
      series: []
    },
    className: '',
    height: ''
  };

  constructor(props) {
    super(props);
    this.myChart = null;
  }

  renderChart(data) {
    this.myChart = echarts.init(this.refs.eCharts);
    this.myChart.setOption(data, true);
    data.operation &&
    data.operation.map(item => {
      this.myChart.on(item.type, function (params) {
        item.function && item.function(params);
      });
    });
    window.addEventListener('resize', this.myChart.resize)
  }

  getConfig({className, data = {}, height}) {
    if (data.toJS) {
      data = fromJS(data).toJS();
    }
    const getSeriesData = (arr = []) => {
      return arr.map((v = {}) => v.data);
    };
    //自己传入的参数需要改变的字段
    const {series = {}, color} = data;
    return {
      className,
      color,
      height,
      seriesData: getSeriesData(series)
    };
  }

  componentDidMount() {
    this.renderChart(this.props.data);
    
  }

  componentWillReceiveProps(nextProps) {
    const compareProps = is(
      fromJS(this.getConfig(this.props)),
      fromJS(this.getConfig(nextProps))
    );
    if (!compareProps) {
      this.renderChart(nextProps.data);
    }
  }

  componentWillUnmount() {
    this.myChart = null;
  }

  render() {
    const {className, height} = this.props;
    return (
      <div
        style={{height: height}}
        className={`ECharts ${className}`}
        ref="eCharts"
      />
    );
  }
}

// module.exports = ECharts;
export default ECharts;