import axiosService from './axios';

const JinBaiHui = axiosService('jinbaihui');

export default {
  displayGaokao: function (params) {
    return JinBaiHui('get', 'gaokaoFacade', 'displayGaokao', { params })
  },
  gaokaoRegionList: params => {
    return JinBaiHui('get', 'gaokaoFacade', 'gaokaoRegionList', { params })
  },
  displayLibraryService: params => {
    return JinBaiHui('get', 'libraryFacade', 'displayLibraryService', { params })
  },
  displayLibrary: params => {
    return JinBaiHui('get', 'libraryFacade', 'displayLibrary', { params })
  },
  displayCourse: params => {
    return JinBaiHui('get', 'courseFacade', 'displayCourse', { params })
  },
  getPollingTime: params => {
    return JinBaiHui('get', 'gaokaoFacade', 'getPollingTime', { params })
  },
  getReaderCount: params => {
    return JinBaiHui('get', 'bigScreen', 'getReaderCount', { params })
  }
};