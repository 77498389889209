import React, { useEffect, useRef, useState } from 'react'

import './ImgList.scss';

export default function ImgList(props) {

  const { imageList, length, className } = props;
  
  const [list, setList] = useState([]);
  const [bigImageUrl, setBigImageUrl] = useState('');
  
  const countRef = useRef(0);
  const timerRef = useRef(null);

  useEffect(() => {
    if(imageList.length <= length) {
      setList(
        [].concat(imageList, new Array(length - imageList.length).fill({}))
      )
    } else {
      setList(imageList.slice(countRef.current, countRef.current + length));
      timerRef.current = setInterval(() => {
        countRef.current++;
        if(countRef.current > imageList.length) {
          countRef.current = 0;
        }
        setList(imageList.slice(countRef.current, countRef.current + length));
      }, 5 * 1000);
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current);
    }
  }, [imageList, length])

  return (
    <>
      {
        list.map((item, key) => {
          return (
            <div className={`${className} item_${key}`} onClick={() => setBigImageUrl(item.img)} key={key}>
              {item.img && <div style={{ backgroundImage: `url(${item.img})`}} className="item-img" />}
            </div>
          )
        })
      }
      {
        !!bigImageUrl &&
        <div className='big-image-wrap_tkyEkcEE' onClick={() => setBigImageUrl('')}>
          <div style={{ backgroundImage: `url(${bigImageUrl})`}} className="item-img" />
        </div>
      }
    </>
  )
}
ImgList.defaultProps = {
  imageList: []
}