import React, { useEffect, useMemo, useRef, useState } from 'react'

import TitleItem from '@/views/XinGaoKao/components/TitleItem/TitleItem';

import './BorrowList.scss';

export default function BorrowList(props) {

  const { data = {} } = props;

  const [lists, setLists] = useState({ weekList: [], monthList: [], yearList: [] });

  const { weekList, monthList, yearList } = useMemo(() => {
    return data.reduce((acc, cur) => {
      if(cur.type === '周榜') {
        acc.weekList.push(cur);
      } else if(cur.type === '月榜') {
        acc.monthList.push(cur);
      } else if(cur.type === '年榜') {
        acc.yearList.push(cur);
      }
      return acc;
    }, { weekList: [], monthList: [], yearList: [] })
  }, [data]);

  const timerRef = useRef(null);
  const wCountRef = useRef(0);
  const mCountRef = useRef(0);
  const yCountRef = useRef(0);

  useEffect(() => {
    const options = {};
    if(weekList.length <= LEN) {
      options.weekList = weekList;
    }
    if(monthList.length <= LEN) {
      options.monthList = monthList;
    }
    if(yearList.length <= LEN) {
      options.yearList = yearList;
    }
    if(!options.weekList || !options.monthList || !options.yearList) {
      const setDataFn = () => {
        let opt = {};
        if(!options.weekList) {
          opt.weekList = weekList.slice(wCountRef.current, wCountRef.current + LEN);
          wCountRef.current++;
          if(wCountRef.current + Math.floor(LEN) > weekList.length) {
            wCountRef.current = 0;
          }
        }
        if(!options.monthList) {
          opt.monthList = monthList.slice(mCountRef.current, mCountRef.current + LEN);
          mCountRef.current++;
          if(mCountRef.current + Math.floor(LEN) > monthList.length) {
            mCountRef.current = 0;
          }
        }
        if(!options.yearList) {
          opt.yearList = yearList.slice(yCountRef.current, yCountRef.current + LEN);
          yCountRef.current++;
          if(yCountRef.current + Math.floor(LEN) > yearList.length) {
            yCountRef.current = 0;
          }
        }
        setLists({
          ...options, ...opt
        })
      }
      setDataFn();
      timerRef.current = setInterval(() => {
        setDataFn();
      }, INTERVAL_TIME);
    } else {
      setLists(options)
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current);
    }
  }, [weekList, monthList, yearList]);

  return (
    <div className='BorrowList_zqTzZz00'>
      <div className='unit-text'>（人/次）</div>
      <div className='list-wrap'>
        <div className='list-item'>
          <TitleItem title={<div className='item-title'>本周</div>} />
          <div className='inner-list'>
            {
              lists.weekList.map((item, index) => {
                return (
                  <div className='user-item' key={index}>
                    <span className='user-name' title={item.region}>{item.region}</span>
                    <span className='book-count'>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className='list-item'>
          <TitleItem title={<div className='item-title'>本月</div>} />
          <div className='inner-list'>
            {
              lists.monthList.map((item, index) => {
                return (
                  <div className='user-item' key={index}>
                    <span className='user-name' title={item.region}>{item.region}</span>
                    <span className='book-count'>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className='list-item'>
          <TitleItem title={<div className='item-title'>本年</div>} />
          <div className='inner-list'>
            {
              lists.yearList.map((item, index) => {
                return (
                  <div className='user-item' key={index}>
                    <span className='user-name' title={item.region}>{item.region}</span>
                    <span className='book-count'>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const LEN = 8;
const INTERVAL_TIME = 1 * 1000;