

import React from "react"
import Tooltip from '../../../UIComponent/Tooltip/Tooltip';
import api from "@/api/tv";
import * as Session from '../../../../utils/session';
import './index.scss'


class Page extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      num: 0
    }
  }
  componentDidMount() {
    if (this.props.interf){
      this.fetchData(this.props.interf)
      // if (Session.get('agentId')) {
      // } else {
      //   api.getSlideSetting().then((data) => {
      //     Session.put('agentId', data.sourceAgentIds[0])
      //     this.fetchData(this.props.interf)
      //   })
      // }
    }
  }

  fetchData = (interf) =>{
    interf&&api[interf]().then((data) => {
      data && this.setState({
        num: data[this.props.keyword] ? data[this.props.keyword] : (Number(data) ? data : 0)
      })
    })
  }

  render() {
    const { num, prefixNum = 0 } = this.props
    // 数据其他增加一百，作者数量，编辑数量，增加50。
    let showNum = (num || this.state.num) ? (num || this.state.num) + prefixNum : 0;
    return <>
      <div className="card">
        <div className="card_title">
          <Tooltip title={this.props.tip}>
            <span className="name">{this.props.title}</span>
            <img src={require('../../../../assets/images/wenhao.png')}></img>
          </Tooltip>
        </div>
        <div className="card_num">
          {showNum}
        </div>
      </div>
    </>
  }
}
export default Page;
