import React, { Component } from 'react'
import { Chart } from '@antv/g2';

import './VisitChart.scss';

export default class VisitChart extends Component {

  state = {
    isLoading: true,
    resourceVisits: 0,
    hourList: [],
    visitorNum: 0,
    monthList: []
  }
  
  componentDidMount() {
    const { courseVisitsHours = [], courseVisitsMonths = [], course = {} } = this.props;
    const hourList = courseVisitsHours.map(v => {
      return {
        ...v,
        key: v.timeHours,
      }
    });
    const monthList = courseVisitsMonths.map(v => {
      return {
        ...v,
        key: v.timeMoth <= 12 ? `${v.timeMoth}月` : v.timeMoth,
      }
    });
    this.setState({
      isLoading: false,
      resourceVisits: course.resourceVisits,
      hourList,
      visitorNum: course.visitorNum,
      monthList
    })
  }

  render() {
    const { resourceVisits, hourList, visitorNum, monthList, isLoading } = this.state;
    if(isLoading) return null;
    return (
      <div className='VisitChart_sJZZ9733'>
        <div className='visit-amount-wrap'>
          <div className='title-wrap'>
            <div className='title-img' />
            <div className='icon-img' />
          </div>
          <div className='amount-wrap'>
            {
              String(resourceVisits).split('').map((v, i) => {
                return <div className='number-content' key={i}>{v}</div>
              })
            }
          </div>
          <div className='chart-title'>全年访问量</div>
          <ChartInner list={monthList} alias="人次" />
        </div>
        
        <div className='visit-amount-wrap visit-person'>
          <div className='title-wrap'>
            <div className='title-img' />
            <div className='icon-img' />
          </div>
          <div className='amount-wrap'>
            {
              String(visitorNum).split('').map((v, i) => {
                return <div className='number-content' key={i}>{v}</div>
              })
            }
          </div>
          <div className='chart-title'>分时访问量</div>
          <ChartInner list={hourList} alias="人数" />
        </div>
      </div>
    )
  }
}

class ChartInner extends React.Component {

  componentDidMount() {
    const { list, alias } = this.props;
    const data = list;
    // https://antv-g2.gitee.io/zh/examples/column/basic#basic
    const chart = new Chart({
      container: this.chartRef,
      autoFit: true,
    });

    chart.data(data);
    chart.scale('value', {
      alias: alias,
      nice: true,
    });
    
    chart.tooltip({
      showMarkers: false
    });
    chart.axis('value', {
      title: null,
      tickLine: null,
      line: null,
      grid: {
        line: {
          type: 'line', // 'line'
          style: {
            stroke: '#fff',
            opacity: 0.3
          }
        }
      },
      label: {
        style: {
          fill: '#fff'
        }
      }
    });
    chart.axis('key', {
      title: null,
      tickLine: null,
      line: null,
      label: {
        style: {
          fill: '#fff'
        }
      }
    });
    
    chart.interval().position('key*value');
    
    chart.render();
    
  }
  
  render() {
    return <div ref={r => this.chartRef = r} className='Chart4Visit'></div>;
  }
}