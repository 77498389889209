import React from "react";
import tv from "@/api/tv";

const getData = {
  timeout: 5000,
  params: {
    count: 10,
    type: 0
  },
  events: ["SCAN", "FOLLOW", "PURCHASE"],
  listener: [],
  flag:true,
  start: function () {
    this.flag = true
    this.events.forEach((item, index) => {
      this.fetchData(index + 1);
    });
  },

  stop(){
    this.flag = false
  },
  fetchData(type,params) {
    const iteration = () => {
      if(!this.flag) return
      tv.getRecentUser(Object.assign({}, getData.params, {type}))
        .then(data => {
          this.listener.map(funcs => {
            if (typeof funcs === "function") {
              funcs(this.events[type - 1], data);
            }
          });
          setTimeout(iteration, getData.timeout);
        })
        .catch(err => {
          setTimeout(iteration, getData.timeout);
        })
    };
    iteration();
  },
  addDataListener(funcs) {
    if (typeof funcs === "function" && this.listener.indexOf(funcs) < 0) {
      this.listener.push(funcs);
      return () => {
        this.listener.filter((v)=>v !== funcs);
      };
    }
  },
  
};

console.log(window.location)
// if(['/','/realtime'].includes(window.location.pathname)){
  // getData.start();
// }

export default getData;
