import React from 'react';
import getData from '@/utils/getData';
import users from "@/assets/images/usershead.jpg"
import RightBlock from '../rightBlock'

import Utils from "../../../Home/utils";
import tv from "@/api/tv";

import './index.scss';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeUserList: [],
      scanUserList: [],
    };
    this.userList = [];
  }

  updateInfo = (type, message = []) => {
    this.userList = this.userList.concat(message?.filter(v => v.customerId));
    if (this.userList.length > 60) {
      this.userList = this.userList.slice(10, 60)
    }
  };

  firstFetch=()=>{
    const params = {
      count: 10,
      type: 0
    };
    const type = Math.floor(Math.random()*2+1);
    tv.getRecentUser(Object.assign({}, params, {type})).then((res)=>{
      this.updateInfo('',res);
      this.show();
    })
  }

  show=()=>{
    let time, counter = 0, newTimes;
    const {changePeople} = this.props;
    if (this.userList.length > 0) {
      clearTimeout(time);
      const realTimeUserIdList = this.state.timeUserList.map(v => v.customerId);
      const filterUserList = this.userList.filter(v => !realTimeUserIdList.includes(v.customerId));
      const data = filterUserList.splice(Math.ceil(Math.random() * (filterUserList.length - 1)), 1)[0] || {};
      // console.log(data,'dataaaaaaa')
      data.time = Utils.getDate();
      const newData = [...this.state.timeUserList, data];

      const codeUserIdList = this.state.scanUserList.map(v => v.customerId);
      const filterCodeUserList = this.userList.filter(v => !codeUserIdList.includes(v.customerId));
      const record = filterCodeUserList.splice(Math.ceil(Math.random() * (filterCodeUserList.length - 1)), 1)[0] || {};
      const newScan = [...this.state.scanUserList, record];

      if (counter >= 3) {
        // clearTimeout(newTimes);
        // newTimes = setTimeout(() => changePeople(record), 2000);
        counter = 0;
      } else {
        counter++
      }

      if (newData.length > 4) {
        newData.shift()
      }

      if (newScan.length > 4) {
        newScan.shift()
      }
      this.setState({
        timeUserList: newData,
        scanUserList: newScan
      });
    }
  }

  /**
   * 每隔2s更新一次用户实时行为的人数
   * 每隔2s更新一次新增扫码用户的人数
   * 每间隔2s获取一次弹出框数据
   */
  beginUser() {
    
    let time, counter = 0, newTimes;
    // const {changePeople} = this.props;
    const show = () => {
      if (this.userList.length > 0) {
        clearTimeout(time);
        const realTimeUserIdList = this.state.timeUserList.map(v => v.customerId);
        const filterUserList = this.userList.filter(v => !realTimeUserIdList.includes(v.customerId));
        const data = filterUserList.splice(Math.ceil(Math.random() * (filterUserList.length - 1)), 1)[0] || {};
        // console.log(data,'dataaaaaaa')
        data.time = Utils.getDate();
        const newData = [...this.state.timeUserList, data];

        const codeUserIdList = this.state.scanUserList.map(v => v.customerId);
        const filterCodeUserList = this.userList.filter(v => !codeUserIdList.includes(v.customerId));
        const record = filterCodeUserList.splice(Math.ceil(Math.random() * (filterCodeUserList.length - 1)), 1)[0] || {};
        const newScan = [...this.state.scanUserList, record];

        if (counter >= 3) {
          // clearTimeout(newTimes);
          // newTimes = setTimeout(() => changePeople(record), 2000);
          counter = 0;
        } else {
          counter++
        }

        if (newData.length > 4) {
          newData.shift()
        }

        if (newScan.length > 6) {
          newScan.shift()
        }
        this.setState({
          timeUserList: newData,
          scanUserList: newScan
        });
      }
      time = setTimeout(show, 2000)
    };
    show();
  }

  componentDidMount() {
    this.firstFetch();
    getData.addDataListener(this.updateInfo);
    getData.start()
    this.beginUser();
  }
  render() {
    return <>
      <RightBlock title={'新增扫码用户'}>
        <ul className='user-bottom-ul'>
          {this.state.scanUserList.map((item) => {
            return (
              <li key={item.customerId} className='user-bottom-li'>
                <div className='user-bottom-img'>
                  <img src={item.headUrl || users}/>
                </div>
                <p>{item.nickName}</p>
                <div className='user-city'>
                  {item.province}{(item.province && item.city) ? '·' : ''}{item.city}
                </div>
              </li>
            )
          })}
        </ul>
      </RightBlock>
      <RightBlock title={'实时用户行为采集'}>
        <div className='user-box'>
          <ul className="user-real-time">
            {this.state.timeUserList.map((item) => {
              const time = item.time;
              let showBookName = item.bookName || item.sceneName || item.productName || '';
              showBookName = showBookName.includes('《') ? showBookName : `《${showBookName}》`;
              //处理书名号
              let titleNumIndex = showBookName.indexOf('》');
              showBookName = titleNumIndex > -1 ? showBookName.slice(0, titleNumIndex + 1) : showBookName;
              return (
                <li key={item.customerId} className='user-li clearfix'>
                  <div className='li-box left'>

                    <span className='user-time'>
                      {`${time.h}:${time.min < 10 ? '0' + time.min : time.min}`}
                    </span>
                    <div className='user-img'>
                      <img src={item.headUrl || users} />
                      <span className={`user-span ${item.sex === 1 ? 'man' : 'woman'}`}>
                        <i className={`ic ic-${item.sex === 1 ? 'male' : 'female'}`} />
                      </span>
                    </div>
                    <div className='user-text'>
                      {
                        item.code === 'FOLLOW' ?
                          <p>{item.nickName} {type2text[item.code]}{item.officialAccountName || item.productName || ''}</p>
                          :
                          <p>{item.nickName} {type2text[item.code] || '扫码'} {showBookName}</p>
                      }

                      <span>{item.province || '湖北'}{(item.province && item.city) ? '·' : ''}{item.city}</span>
                    </div>
                  </div>

                  <div className='user-out right'>
                    {/* <span>{item.officialAccountName}</span>
                    <span>{item.agentName}</span> */}
                    <p>{item.productName}</p>
                    <span>{item.agentName}</span>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </RightBlock>
      
    </>
  }
}
const type2text = {
  FOLLOW: '关注',
  SCAN: '扫码'
};
export default Page;