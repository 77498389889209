import React, { Component } from 'react'
import { Chart } from '@antv/g2';

export default class BookPublishChart extends Component {

  initData(list) {
    let maxNum = 0;
    const data = list.map(item => {
      item.bookSegment = item.bookSegment.length > 5 ? item.bookSegment.slice(0, 4) + '···' : item.bookSegment
      if(item.value > maxNum) maxNum = item.value;
      return item;
    });
    const chart = new Chart({
      container: this.charRef,
      autoFit: true,
    });
    chart.data(data);
    chart.scale({
      value: {
        max: maxNum * 1.2,
        min: 0,
        alias: '数量'
      },
    });
    // 坐标轴
    chart.axis('bookSegment', {
      title: null,
      tickLine: null,
      line: null,
      label: {
        style: {
          fill: '#fff'
        }
      }
    });
    chart.axis('value', false);
    chart.legend(false);
    chart.coordinate().transpose();
    chart
      .interval()
      .position('bookSegment*value')
      .size(26)
      .label('value', {
        style: {
          fill: '#fff',
        },
        offset: 10,
      })
      .color('sales', (xVal) => {
        return 'l(0) 0:#69FBBC 1:#39ADF1';
      });
    ;
    // chart.interaction('element-active');
    chart.render();
  }
  componentDidMount() {
    const { list } = this.props;
    this.initData(list);
  }
  
  render() {
    return (
      <div ref={r => this.charRef = r} style={{ height: '100%' }} className='BookPublishChart'></div>
    )
  }
}
