import React, { useEffect, useRef, useState } from 'react'
import TitleItem from '../TitleItem/TitleItem'

import './Table.scss';

export default function Table(props) {

  // titleList: [{ key: 'sub', title: '科目' }, { key: 'score', title: '分数'} ]
  // itemList: [{ 'sub': '数学', 'score': 91 }]
  const { titleList = [], itemList, className = '', lines, interval = 1 } = props;

  const [list, setList] = useState([]);
  
  const timerRef = useRef(null);
  const countRef = useRef(0);
  
  useEffect(() => {
    if(lines && itemList.length > lines) {
      setList(itemList.slice(countRef.current, countRef.current + lines));
      timerRef.current = setInterval(() => {
        countRef.current = countRef.current + 1;
        if(countRef.current + Math.ceil(lines / 2) > itemList.length) {
          countRef.current = 0;
        }
        setList(itemList.slice(countRef.current, countRef.current + lines));
      }, interval * 1000);
    } else {
      setList(itemList);
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current);
    }
  }, [lines, itemList, interval]);
  
  return (
    <div className={`Table_VsDDOj99 ${className}`}>
      <table>
        <thead>
          <tr>
            {
              titleList.map((titleItem) => {
                return <th className={`th_${titleItem.key}`} key={titleItem.key}><TitleItem title={titleItem.title} /></th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            !!list.length && list.map((item, index) => {
              return (
                <tr key={index}>
                  {
                    titleList.map((titleItem, i) => {
                      const {renderFn} = titleItem;
                      return (
                        <td key={titleItem.key}>
                          <div
                            className={titleItem.key}
                            title={typeof renderFn !== 'function' ? item[titleItem.key] : ''}
                          >
                          {
                            typeof renderFn === 'function' ? renderFn(item) :
                            item[titleItem.key]
                          }
                          </div>
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {!itemList.length && <div className='empty-tip'>暂无数据</div>}
    </div>
  )
}

Table.defaultProps = {
  itemList: []
}