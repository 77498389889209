/**
 *  Date    : 2020/10/18
 *  Author  : swq
 *  Declare : SettingModal
 *
 */


import React from "react";
import api from "@/api/tv";

import * as Session from '../../utils/session';

import "./SettingModal.scss";

class SettingModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newPeople: {},
      playStatus: 'hand',
      isShow: false,
      publish_list: [],
      selectedId: null,
      confirmPlayStatus: '',
      initPlayStatus: ''
    }
    this.timer = null
    this.startX = 0
    this.endX = 0
    this.routeList = []
  }

  playStyle = (flag) => {
    if (flag === 'auto') {
      this.setState({ playStatus: 'auto' })
    } else {
      this.setState({ playStatus: 'hand' })
    }
  }

  autoPlay = (flag) => {
    if (flag === 'hand') return clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.routeTo('next')
    }, 60000)
  }

  routeTo = (params) => {
    const { location: { pathname }, history } = this.props
    let currentRoute = JSON.parse(Session.get('routeList')).filter((v) => v.route === pathname)
    const route_list = JSON.parse(Session.get('routeList'))
    if (route_list.length === 1 ) return //若只设置了一个页面则不跳转
    if (!currentRoute.length) {
      //若当前处在当前页，平台端大屏设置取消了该页面，则下一页随机在剩下的页面中去随机整数跳转
      history.replace(`${route_list[Math.floor(Math.random() * (route_list.length - 0)) + 0].route}`)
    } else {
      let currentRouteId = route_list.filter((v) => v.id === currentRoute[0].id)[0].id
      let currentRouteIndex = route_list.findIndex((item) => item.id === currentRouteId)
      if (!currentRoute.length) return
      if (params === 'next') {
        if (currentRoute[0].id === route_list[route_list.length - 1].id) {
          history.replace(`${route_list[0].route}`)
        } else {
          history.replace(route_list[currentRouteIndex + 1].route)
        }
      } else {
        if (currentRoute[0].id === route_list[0].id) {
          history.replace(`${route_list[route_list.length - 1].route}`)
        } else {
          history.replace(route_list[currentRouteIndex - 1].route)
        }
      }
    }
  }

  confirm = () => {
    const { selectedId, playStatus } = this.state
    document.querySelector('#SettingModal-box').style.visibility = 'hidden'
    this.setState({
      confirmPlayStatus: playStatus
    }, () => {
      api.updateSlideSetting(selectedId ? {
        sourceAgentId: selectedId,
        playingMode: this.state.confirmPlayStatus === 'auto' ? false : true
      } : { playingMode: this.state.confirmPlayStatus === 'auto' ? false : true }).then(() => {
        this.autoPlay(this.state.confirmPlayStatus)
        Session.put('agentId', selectedId)
        window.location.reload();
      })
    })

  }

  selectPublish = (id) => {
    this.setState({
      selectedId: id,
    })
  }

  onClose = () => {
    this.setState({
      playStatus: this.state.initPlayStatus
    })
    document.querySelector('#SettingModal-box').style.visibility = 'hidden'
  }

  mouseup = (e) => {
    if (e.target.className !== 'SettingModal-box' || e.button === 2) return
    const { playStatus } = this.state
    this.endX = e.clientX;
    if (playStatus === 'auto') return
    if (this.endX - this.startX > 0) {
      this.routeTo('next')
    } else if (this.endX - this.startX < 0) {
      this.routeTo('pre')
    }
  }

  mousedown = (e) => {
    this.startX = e.clientX;
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
  }

  componentDidMount = () => {

    api.getSlideSetting().then((data) => {
      Session.put('agentId', data.sourceAgentIds[0])
      this.setState({
        playStatus: data.playingMode ? 'hand' : 'auto',
        initPlayStatus: data.playingMode ? 'hand' : 'auto',
        selectedId: data.sourceAgentIds[0],
        rerenderKey: Math.random()
      }, () => {
        this.autoPlay(this.state.playStatus)
      })
    })
    api.getTimesAgents().then((data) => {
      this.setState({
        publish_list: data.length && data.map((item) => {
          return { id: item.agentId, publish_name: item.agentName }
        })
      })
    })
    // api.getScreenShow().then((data) => {
    //   for (const key in data) {
    //     if (data[key]) {
    //       this.routeList.push(ROUTES_LIST.filter(v => v.key === key)[0])
    //     }
    //   }

    // })
  }

  render() {
    const { playStatus, isShow, rerenderKey } = this.state
    return (
      <div className="SettingModal-box" onMouseUp={(e) => this.mouseup(e)} onMouseDown={(e) => this.mousedown(e)} id='SettingModal-box' style={{ visibility: isShow ? 'initial' : 'hidden' }}>
        <div id='SettingModal' >
          <div className="setting-container">
            <div className="SettingModal-title">设置</div>
            {/* 播放方式 */}
            <div className="play-style" key={rerenderKey}>
              <label className="play-style-label">播放方式</label>
              <div className="handPlay" onClick={()=>this.playStyle('hand')}>
                <input id="input_id_qw1ewq" type="radio" name='playStatus' value='hand' defaultChecked={playStatus === 'hand' ? true : false} onChange={() => {
                  this.playStyle('hand')
                }}></input><label htmlFor="input_id_qw1ewq" className="play-style-hand">手动滑动</label>
              </div>
              <div className="autoPlay" onClick={()=>this.playStyle('auto')}>
                <input id="input_id_qw1ewa" type="radio" name='playStatus' value='auto' defaultChecked={playStatus === 'auto' ? true : false} onChange={() => {
                  this.playStyle('auto')
                }}></input><label htmlFor="input_id_qw1ewa" className="play-style-auto">自动循环</label>
              </div>

            </div>

            {/* 数据来源 */}
            {/* <div className="data-sources">
              <label className="data-sources-label">数据来源</label>
              <div className="publish-list">
                {[{ id: -1, publish_name: '全部出版社' }].concat(publish_list).map((v, index) => {
                  return (
                    <div key={index} className="publish-item" onClick={() => this.selectPublish(v.id)} >
                      <input type="radio" name='publishName' value={v.id} defaultChecked={selectedId === v.id} ></input>
                      <label className="publish-item-label">{v.publish_name}</label>
                    </div>
                  )
                })}
              </div>
            </div> */}

            <div className="btn-box">
              <div className="confirm-btn" onClick={() => this.confirm()}>确定</div>
              <div className="cancel-btn" onClick={() => this.onClose()}>取消</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingModal;

// const ROUTES_LIST = [
//   { key: 'homePageShow', id: 1, route: '/' },
//   { key: 'homePageShow', id: 1, route: '/newHome' },
//   { key: 'dynamicShow', id: 5, route: '/realtime' },
//   { key: 'incomeOverviewShow', id: 2, route: '/data' },
//   { key: 'productExperienceShow', id: 3, route: '/product' },
//   { key: 'userOverviewShow', id: 4, route: '/user' },
//   { key: 'groupDataShow', id: 6, route: '/community' },
// ]
