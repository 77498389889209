'use strict';

const screenW = document.documentElement.clientWidth;

const width = 3840;

export default {
  getPx(px) {
    return `${px * screenW / width}px`;
  },
  getDate(time) {
    const d = time ? new Date(time) : new Date();
    const year = d.getFullYear(),
      month = d.getMonth(),
      day = d.getDate(),
      week = d.getDay(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds(),
      ms = d.getMilliseconds();
    return {
      Y: year,
      M: month,
      W: week,
      D: day,
      h: hours,
      min: minutes,
      s: seconds,
      ms: ms
    }
  },
  getString(number) {
    if(Number(number)) {
      const numberArr = number.toString().split("");
      return numberArr.reverse().map((item, index) => {
        if((index + 1) % 3 === 0 && index !== numberArr.length - 1) {
          return `,${item}`
        } else {
          return item;
        }
      }).reverse().join("").split('')
    } else {
      return [];
    }
  }
}