import React, { Component } from 'react'
import { Chart, getTheme } from '@antv/g2';

export default class ClassifyList extends Component {

  initData(list) {
    let data = list.map(item => ({
      year: item.classificationName,
      population: item.value
    }));
    const totalData = list.reduce((acc, cur) => acc + cur.value, 0);
    const { height } = this.chartRef.getBoundingClientRect();

    const chart = new Chart({
      container: this.chartRef,
      autoFit: true,
      // height: 500,
    });
    chart.data(data);
    chart.coordinate('polar', {
      innerRadius: 0.2,
    });
    chart.legend('year', {
      position: 'right',
    });
    chart.axis(false);
    chart.tooltip({
      showTitle: false,
      showMarkers: false
    });
    chart.legend('year', {
      position: 'right',
      // itemValue itemName 都会渲染一个图例文本！！！
      itemValue: {
        style: { fill: '#fff'}
      },
      // 清除 
      itemName: {
        formatter: (text) => ''
      }
    });
    chart.interaction('element-highlight');
    chart
      .interval()
      .position('year*population')
      .label('population', {
        content:  (data) => {
          let v = Math.round(data.population / totalData * 100) + '%'
          return v;
        },
        offset: '-10%',
        layout: {
          type: 'limit-in-shape',
        },
        background: {
          style: {
            fill: '#000',
            fillOpacity: '0.25',
            radius: 2,
          },
          padding: 2,
        },
      })
      .color('year')
      .style({
        lineWidth: 1,
        stroke: '#fff',
      });
    chart.render();

  }

  componentDidMount() {
    const { list = []} = this.props
    this.initData(list);
  }
  
  render() {
    return (
      <div className='ClassifyList' style={{ height: '100%'}} ref={r => this.chartRef = r}></div>
    )
  }
}
