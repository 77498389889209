/*
 * @Author: paco
 * @Date: 2018-04-11 22:53:03
 * @Last Modified by: paco
 * @Last Modified time: 2018-04-13 16:21:22
 */


import axios from 'axios';
import * as Session from '../utils/session';
import baseConfig from '../config';

const isDevelopment = process.env.NODE_ENV === 'development';
axios.defaults.baseURL = window.location.origin + (isDevelopment ? "/api/" : "/");



//Add a response interceptor
axios.interceptors.response.use(function (response) {
  const data = response.data, status = response.statusText;
  if (data.errCode === 0 || data) {
    if (data.data === 0) {
      return data.data
    } else {
      return data.data || data;
    }
  } else {
    return Promise.reject(data);
  }
}, function (error) {
  if (error.status === 500) {
    return Promise.reject({
      message: "服务器内部错误，请稍后重试！"
    })
  }
  if (error.status === 502) {
    return Promise.reject({
      message: "网络问题，请稍后重试！"
    })
  }
  return Promise.reject(error);
});

export default function axiosServices(root) {
  if (root === 'times') {
    // axios.defaults.baseURL = window.location.origin + (isDevelopment ? "/rays/" : "/");
    return (method, api, id, data) => {
      return axios[method](root + '/' + api + (id ? ('/' + id) : ''), data)
    };
  } else {
    // axios.defaults.baseURL = window.location.origin + (isDevelopment ? "/api/" : "/");
    return (method, api, id, data) => {
      return axios[method](root + '/v1.0/' + api + (id ? ('/' + id) : ''), data)
    };
  }
}



/**
 * 筛选出版社重新为接口参数赋值
 * 
 */
const addToken = (config) => {
  // if(config.url==='times/v1.0/setting/getSlideSetting')  config.params = {}
  if (config.url === 'times/setting/getSlideSetting' || config.url === 'times/setting/getScreenShow' || config.url === 'times/setting/getBookCodeInfo' || config.url === 'times/setting/getAppCodeInfo' ||
  config.url === 'times/setting/updateSlideSetting'||config.url==='times/project/getTimesAgents'
  ) {
    config.params = {}
  } else if (config.params) {
    if (baseConfig.agentId) {
      config.params = Object.assign({}, config.params, { agentId: baseConfig.agentId })
    }
  } else {
    config.params = { agentId: baseConfig.agentId }
  }
  return config;
};

axios.interceptors.request.use(addToken, console.error);


