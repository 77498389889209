import React, { Component } from 'react'

import './Applications.scss';

export default class Applications extends Component {
  render() {
    const { course = {} } = this.props;
    return (
      <div className='Applications_sJZZ9733'>
        {
          APP_LIST.map((item) => {
            return (
              <div className='app-item-wrap' key={item.key}>
                <div className={`app-img img_${item.id}`} />
                <div className='app-title'>{item.title}</div>
                <div className='app-visit-count'>{course[item.key]}</div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

const APP_LIST = [
  { id: 1, title: '在线题库', key: 'questionVisits' },
  { id: 2, title: '专家问答', key: 'expertVisits' },
  { id: 3, title: '视频学习', key: 'videoVisits' },
  { id: 4, title: '电子书', key: 'evistis' },
  { id: 5, title: '音频学习', key: 'audioVisits' },
  { id: 6, title: '读书笔记', key: 'noteVisits' },
  { id: 7, title: '活动报名', key: 'registionVistis' },
  { id: 8, title: '社群交流', key: 'communityVistis' },
]