import React, { useEffect, useState } from 'react'

import './ProcessList.scss';

export default function ProcessList(props) {

  const { lists = {}} = props;

  const [currentIndex, setIndex] = useState(0);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(index => {
        return (index + 1) % 4;
      })
    }, 3 * 1000);
    return () => {
      timer && clearInterval(timer);
    }
  }, []);

  const type = TITLE_LIST[currentIndex].type;
  return (
    <div className='ProcessList'>
      <div className='process-title-wrap'>
        {
          TITLE_LIST.map((item, index) => {
            const active = currentIndex === index;
            return (
              <React.Fragment key={item.type}>
                {index !== 0 && <div className='arrow-icon' />}
                <div className={`title-item-wrap ${active ? 'active' : ''}`}>
                  {item.type}
                </div>
              </React.Fragment>
            )
          })
        }
      </div>
      <div className='image-list-wrap'>
        <div className={`triangle-icon icon_${currentIndex}`} />
        <div className='image-list'>
          {
            (lists[type] && lists[type].length) ? lists[type].slice(0, 4).map(item => {
              return (
                <div className='image-item-wrap'>
                  <div className='image-item' style={{ backgroundImage: `url(${item.img})`}} />
                </div>
              )
            }) : (
              <div className='image-empty' />
            )
          }
        </div>
      </div>
    </div>
  )
}

const TITLE_LIST = [
  { type: '清理回溯' },
  { type: '采配达标' },
  { type: '管理服务' },
  { type: '阅读课程' },
]