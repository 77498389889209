import React, { Component } from 'react'
import { Chart } from '@antv/g2';

export default class PopularBook extends Component {

  initData(list) {
    let maxNum = 0;
    const data = list.map(item => {
      item.categoryName = item.categoryName.length > 5 ? item.categoryName.slice(0, 4) + '···' : item.categoryName
      if(item.value > maxNum) maxNum = item.value;
      return item
    });
    const chart = new Chart({
      container: this.containerRef,
      autoFit: true,
    });
    chart.data(data);
    chart.scale({
      value: {
        max: maxNum * 1.1,
        min: 0,
        alias: '人次'
      },
    });
    chart.axis('categoryName', {
      label: {
        offset: 20,
        style: { fill: '#fff'}
      }
    });
    chart.axis('value', {
      // title: {
      //   text: '(人/次)',
      //   autoRotate: false,
      //   position: 'end',
      //   offset: 30,
      //   style: { fill: '#fff'}
      // },
      label: {
        style: { fill: '#fff'}
      }
    });
    

    chart.area().position('categoryName*value').color('white').style({
      fillOpacity: 0.3,
    });
    chart.line().position('categoryName*value').color('white');
    // 分段颜色
    chart.annotation().regionFilter({
      top: true,
      start: ['min', 'max'],
      end: ['max', 0],
      color: '#2056F3'
    });
    chart.render();
  }
  
  componentDidMount() {
    const { list = [] } = this.props;
    this.initData(list.slice(0, 6));
  }
  
  render() {
    return (
      <div className='PopularBook' style={{ height: '90%'}} ref={r => this.containerRef = r}></div>
    )
  }
}
