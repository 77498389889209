import React, { Component } from 'react'
import { Chart, registerShape } from '@antv/g2';

export default class ResourceChart extends Component {

  initData(list) {
    
    // const data = [
    //   { type: '分类一', value: 20 },
    //   { type: '分类二', value: 18 },
    //   { type: '分类三', value: 32 },
    //   { type: '分类四', value: 15 },
    //   { type: 'Other', value: 15 },
    // ];
    let total = 0;
    const data = list.map(item => {
      total += item.value;
      return {
        ...item,
        type: item.businessSegment,
        value: item.value
      };
    })
    
    // 可以通过调整这个数值控制分割空白处的间距，0-1 之间的数值
    const sliceNumber = 0.01;
    
    // 自定义 other 的图形，增加两条线
    registerShape('interval', 'slice-shape', {
      draw(cfg, container) {
        const points = cfg.points;
        let path = [];
        path.push(['M', points[0].x, points[0].y]);
        path.push(['L', points[1].x, points[1].y - sliceNumber]);
        path.push(['L', points[2].x, points[2].y - sliceNumber]);
        path.push(['L', points[3].x, points[3].y]);
        path.push('Z');
        path = this.parsePath(path);
        return container.addShape('path', {
          attrs: {
            fill: cfg.color,
            path,
          },
        });
      },
    });
    
    const chart = new Chart({
      container: this.charRef,
      autoFit: true,
    });
    
    chart.data(data);
    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.6,
    });
    chart.tooltip({
      showTitle: false,
      showMarkers: false,
    });
    // 辅助文本
    chart
      .annotation()
      .text({
        position: ['50%', '50%'],
        content: total,
        style: {
          fontSize: 28,
          fill: '#fff',
          textAlign: 'center',
        },
        offsetY: -20,
      })
      .text({
        position: ['50%', '50%'],
        content: '数据显示',
        style: {
          fontSize: 16,
          fill: '#fff',
          textAlign: 'center',
        },
        // offsetX: -10,
        offsetY: 20,
      });
    chart.legend('type', {
      position: 'left',
      // itemValue itemName 都会渲染一个图例文本！！！
      itemValue: {
        style: { fill: '#fff', fontSize: 16 },
        formatter: (text, item, index) => {
          // console.log('text, item, index', text, item, index);
          return `${text} ${data[index].value}`;
        }
      },
      // 清除 
      itemName: {
        formatter: (text) => ''
      }
    });
    chart
      .interval()
      .adjust('stack')
      .position('value')
      .color('type')
      .shape('slice-shape');
    
    chart.render();
  }
  
  componentDidMount() {
    const { list } = this.props;
    this.initData(list);
  }
  
  render() {
    return (
      <div ref={r => this.charRef = r} className='ResourceChart' style={{ height: '100%'}}></div>
    )
  }
}
