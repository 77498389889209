/*
 * @Author: paco
 * @Date: 2018-04-13 13:33:20
 * @Last Modified by: paco
 * @Last Modified time: 2018-04-13 13:36:56
 */



const Utils = {
  getImg(src = "") {
    return src.replace(/oss.5rs.me/, "file.5rs.me");
  },
  setSize(src = "", size = 100) {
    return src + "?x-oss-process=image/resize,p_" + size;
  },
  getString(number) {
    if (Number(number)) {
      const index = number.toString().indexOf('.');
      let numberArr = '';
      let sliceNum = '';
      if (index > -1) {
        numberArr = number.toString().slice(0, index).split('');
        sliceNum = number.toString().slice(index);
      } else {
        numberArr = number.toString().split("");
      }
      const res = numberArr.reverse().map((item, index) => {
        if ((index + 1) % 3 === 0 && index !== numberArr.length - 1 && item !== '.') {
          return `,${item}`
        } else {
          return item;
        }
      }).reverse().join("").split('');
      return res.concat([sliceNum])
    } else {
      return "";
    }
  },
  getStringOld(number) {
    if (Number(number)) {
      const numberArr = number.toString().split("");
      return numberArr.reverse().map((item, index) => {
        if ((index + 1) % 3 === 0 && index !== numberArr.length - 1 && item !== '.') {
          return `,${item}`
        } else {
          return item;
        }
      }).reverse().join("").split('')
    } else {
      return "";
    }
  },
  getDate(time) {
    const d = time ? new Date(time) : new Date();
    let year = d.getFullYear(),
      month = d.getMonth(),
      day = d.getDate(),
      week = d.getDay(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds();
    month = month + 1;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return {
      Y: year,
      M: month,
      W: week,
      D: day,
      H: hours,
      min: minutes,
      S: seconds,
    };
  },
  // 验证数据是否不为空（空值时返回false，null、undefined、空字符串、空数组、空对象都被设定为空）
  isNotEmpty: (value) => {
    switch (typeof (value)) {
      case 'undefined': {
        return false;
      }

      case 'string': {
        return value.length !== 0;
      }

      case 'object': {
        if (Array.isArray(value)) {
          return value.length !== 0;
        } else if (value === null) {
          return false;
        } else {
          return Object.keys(value).length !== 0;
        }
      }

      default: {
        return true;
      }
    }
  }
};

export default Utils;
