/**
 *  Date    : 2018/9/4
 *  Author  : yp
 *  Declare : header
 *
 */

import React from "react";

import Data from "./Data";

import "./Header.scss";

class Header extends React.Component {

  redirectTo(url) {
    // window.location.href = url;
  }

  render() {
    const {title, text} = this.props;
    return (
      <div className="Header">
        <div className="box">
          <span className='img-box'/>
          <span className="page-title" >
            {title}
          </span>
          <span
            className="page-desc"
          >
            {text}
          </span>
        </div>
        <span>
          <Data/>
        </span>
      </div>
    );
  }
}

export default Header;


